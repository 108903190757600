"use client";

import { FilterModal } from "@app/dashboard/news/Filter";
import NewsList from "@app/dashboard/news-feed/news-list/NewsList";
import { FilterSetSelect } from "@app/dashboard/news-feed/FilterSetSelect";
import { Search } from "@app/dashboard/news-feed/Search";
import { SwitchDisplayButton } from "@app/dashboard/news-feed/SwitchDisplayButton";
import { FilterConfigureButton } from "@app/dashboard/news-feed/FilterConfigureButton";
import { NewsFeedContextProvider } from "@app/dashboard/news-feed/NewsFeedContext";
import { FilterContextProvider } from "../news/FilterContext";

export default function NewsFeed({ handles }) {
    return (
        <NewsFeedContextProvider>
            <div className="scrollbar-hide relative flex h-full w-[calc(100vw-16px)] flex-col overflow-y-auto rounded-t-lg lg:w-[calc((100vw-24px)/2)] 1xl:w-[calc((100vw-32px)/3)]">
                <FilterContextProvider>
                    <FilterModal handles={handles} />
                </FilterContextProvider>
                <div className="top-[3.78rem] -ml-px flex w-[calc(100vw-16px)] items-center rounded-t-lg border border-t-0 border-royal-blue bg-base-100 px-2 py-1.5 lg:w-[calc((100vw-24px)/2)] 1xl:w-[calc((100vw-32px)/3)]">
                    <div className="flex w-[calc(100%-110px)] items-center gap-2">
                        <Search />
                        <FilterConfigureButton />
                    </div>
                    <div className="h-0 w-4 rotate-90 border border-solid border-oxford-blue" />
                    <div className="flex items-center gap-2">
                        <FilterSetSelect />
                        <SwitchDisplayButton />
                    </div>
                </div>
                <NewsList />
            </div>
            {/* <PlayPauseFeed /> */}
        </NewsFeedContextProvider>
    );
}
