import { EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDeleteFilterSet, useFilterSets, useSaveFilterSetById } from "@/services/news";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { Dispatch, SetStateAction, useRef } from "react";
import { useMap } from "react-use";
import { FilterSet, UpgradeSection, defaultFilterSets } from "@/types/util";
import MemoLoading from "@assets/SVG/Loading";
import { useUserSubscription } from "@/services/payment";
import { Popover } from "react-tiny-popover";
export default function FilterSetPopover({
    f,
    showOptions,
    IShouldShowUpdateUption,
    setShowOptions,
    setFilterSetEdited,
    setShowEditModal,
}: {
    f: FilterSet;
    showOptions: Record<string, boolean>;
    IShouldShowUpdateUption: boolean;
    setShowOptions: <K extends string>(key: K, value: boolean) => void;
    setFilterSetEdited: Dispatch<SetStateAction<FilterSet>>;
    setShowEditModal: Dispatch<SetStateAction<boolean>>;
}) {
    const { filterSet, setFilterSet } = useNewsFeedContext();
    const { showUpgradeModal, setShowUpgradeModal, setUpgradeMessage } = useFilterContext();
    const { data: filterSets } = useFilterSets();
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const [loading, { set: setLoading }] = useMap({} as Record<string, boolean>);
    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;
    const { mutateAsync: saveFilterSet } = useSaveFilterSetById();
    const { mutateAsync: deleteFilterSet } = useDeleteFilterSet();

    const handleDeleteFilterset = (f: FilterSet) => {
        setLoading(f.filterIndex, true);

        deleteFilterSet({ filterSet: f }).then(() => {
            setLoading(f.filterIndex, false);
            if (filterSets) {
                const filterIndex = filterSets.findIndex((i) => i.filterIndex === f.filterIndex);
                if (filterIndex > -1) {
                    const filterSetBeforeDeleted = filterSets[filterIndex - 1];
                    if (filterSetBeforeDeleted) setFilterSet(filterSetBeforeDeleted);
                    else setFilterSet(defaultFilterSets[0]);
                }
            }
        });
    };

    const handleShowEditModal = (f: FilterSet) => {
        setFilterSetEdited(f);
        setShowEditModal(true);
        setShowOptions(f.filterIndex, false);
    };

    return loading[f.filterIndex] ? (
        <MemoLoading className="w-3 shrink-0" />
    ) : (
        <Popover
            isOpen={showOptions[f.filterIndex] || IShouldShowUpdateUption}
            positions={["bottom", "top", "left", "right"]}
            onClickOutside={() => {
                setShowOptions(f.filterIndex, false);
                Object.keys(showOptions).forEach((index) => setShowOptions(index, false));
            }}
            transformMode="relative"
            transform={{ top: 12, left: -5 }}
            boundaryElement={scrollContainerRef.current || undefined}
            containerClassName="z-[1000]"
            content={
                IShouldShowUpdateUption ? (
                    // this is useful so we can use use source toggle buttons for saved news also
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            saveFilterSet({
                                filterSet, //the updated version of filterSet
                            });
                        }}
                        className="flex cursor-pointer items-center rounded-lg border border-dark-blue bg-slate-blue px-3 py-1 text-xs text-light-blue hover:brightness-75"
                    >
                        Update
                    </div>
                ) : (
                    <div className="flex items-center gap-4 rounded-lg border border-dark-blue bg-slate-blue px-3 py-1 text-light-blue">
                        <TrashIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteFilterset(f);
                                setShowOptions(f.filterIndex, false);
                            }}
                            className="size-[12.5px] cursor-pointer hover:brightness-75"
                        />
                        <PencilSquareIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                handleShowEditModal(f);
                                setShowOptions(f.filterIndex, false);
                            }}
                            className="size-[12.5px] cursor-pointer hover:brightness-75"
                        />
                    </div>
                )
            }
        >
            <EllipsisVerticalIcon
                onClick={(e) => {
                    if (userIsPro) {
                        e.stopPropagation();
                        setShowOptions(f.filterIndex, !showOptions[f.filterIndex]);
                        Object.keys(showOptions).forEach((index) => {
                            // Skip the currently clicked filter index
                            if (index !== f.filterIndex) {
                                // Set the visibility of delete buttons for other filter sets to false
                                setShowOptions(index, false);
                            }
                        });
                    } else {
                        if (!showUpgradeModal) {
                            setUpgradeMessage(UpgradeSection.Filter_Creation);
                            // setOpenFilterModal(false);
                            setShowUpgradeModal(true);
                        }
                    }
                }}
                className="size-3 shrink-0 text-white hover:text-light-blue"
            />
        </Popover>
    );
}
