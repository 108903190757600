// types for blockbeat data structures
export type News = {
    slug: string;
    publishedAt: string;
    headline: string;
    source: string;
    status: string;
    assets: any[];
    author?: string;
    iconType: string;
    tags: {
        type: string;
        data: string;
    }[];
    tagsData?: {
        type: string;
        data: string;
    }[];
    url?: string;
    upVote: number;
    downVote: number;
    content: string;
    data: any;
    new?: boolean;
    read: boolean;
    bookmarked?: boolean;
    ai: any;
    enrichment: any;
    addedAt?: number;
    NK?: number;
    SK?: string;
    sentiment?: any;
    negativeSentiment?: number;
    neutralSentiment?: number;
    positiveSentiment?: number;
};

export type Shortlink = {
    shortlink: string;
    slug: string;
    success: boolean;
};

export type Asset = {
    cmc: {
        symbol: string;
        name: string;
        id: number;
        slug: string;
        tags: any[];
    };
    created: string;
    data: object;
    modified: string;
    price: object;
    NK: number;
    SK: string;
};

export enum SOURCES {
    MEDIUM = "Medium",
    REDDIT = "Reddit",
    COINMARKETCAP = "Coin Market Cap",
    DEFILAMA = "Defi Lama",
    TIINGO = "Tiingo",
    TWITTER = "Twitter",
    BUSINESSWIRE = "Business Wire",
    CISION = "Cision",
    GLOBENEWSWIRE = "GlobeNewswire",
    NEWSFILE = "News File",
    NEWSWIRE = "NewsWire",
    BLOCKBEAT = "BlockBeat",
    CRYPTOSLATE = "Cryptoslate",
    FORSKASTNEWS = "Forskastnews",
}

export enum STATUS {
    NEW = "New",
    HOT = "Hot",
    IMPORTANT = "Important",
    UNREAD = "Unread",
    ACTIVATED = "Activated",
    READ = "Read",
    HOVERED = "Hovered",
}

export enum DISPLAY {
    NORMAL = "normal",
    THIN = "thin",
}

export enum TIER {
    GUEST = "guest",
    FREE = "free",
    PRO = "pro",
}

export enum SubscriptionPlans {
    Basic = "Basic",
    Pro = "Pro",
}

export const options = ["1", "2", "3", "4"];
