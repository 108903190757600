import { Button } from "@components";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";

export default function ShowResultsButton() {
    const { setOpenFilterModal } = useNewsFeedContext();
    return (
        <Button
            type="submit"
            onClick={() => setOpenFilterModal(false)}
            className="h-10 w-full items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-4 py-3"
        >
            <p className="text-xs font-normal text-white">Done</p>
        </Button>
    );
}
