import MemoCryptonews from "@assets/SVG/Cryptonews";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoReddit from "@assets/SVG/Reddit";
import MemoSpeaker from "@assets/SVG/Speaker";
import MemoX from "@assets/SVG/X";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import MemoNews from "@assets/SVG/News";
import MemoGavel from "@assets/SVG/Gavel";
import MemoYouTube from "@assets/SVG/YouTube";
import MemoPodcast from "@assets/SVG/Podcast";
import SourceToggle from "./SourceToggle";

export default function NewsSources() {
    return (
        <div className="flex w-full flex-col items-start gap-2 px-4 py-3">
            <p className="text-xs font-light text-light-blue">Sources</p>
            {/* <div className="flex w-full items-center justify-between gap-2.5 px-0 py-1">
                <p className="text-xs font-normal text-white">Low Quality News</p>
                <Toggle checked={true} disabled={true} />
            </div> */}
            <div className="w-full border-b border-dark-blue">
                <SourceToggle
                    sourceKey="showPrimarySourcesOnly"
                    label="Primary Sources Only"
                    icon={<CheckBadgeIcon className="size-4" color="#FFFFFF" />}
                />
            </div>

            <SourceToggle
                sourceKey="showBlockbeatNews"
                label="BlockBeat News"
                icon={<MemoLogo className="h-2.5 w-2" color="#FFFFFF" />}
            />
            <SourceToggle
                sourceKey="showTwitterNews"
                label="X"
                icon={<MemoX className="size-4 rounded-full bg-black" />}
            />
            <SourceToggle
                sourceKey="showRedditNews"
                label="Reddit"
                icon={<MemoReddit className="size-4" />}
            />
            <SourceToggle sourceKey="showNewsNews" label="News" icon={<MemoNews className="size-4" />} />
            <SourceToggle
                sourceKey="showPressRelease"
                label="Press Release"
                icon={<MemoSpeaker className="size-4" />}
            />
            <SourceToggle
                sourceKey="showGovernmentPress"
                label="Government Press"
                icon={<MemoGavel className="size-4" />}
            />
            <SourceToggle
                sourceKey="showBlogNews"
                label="Blog"
                icon={<MemoCryptonews className="size-4" />}
            />
            <SourceToggle
                sourceKey="showYouTubeNews"
                label="YouTube"
                icon={<MemoYouTube className="size-4" />}
            />
            <SourceToggle
                sourceKey="showPodcastNews"
                label="Podcast"
                icon={<MemoPodcast className="size-4" />}
            />
        </div>
    );
}
