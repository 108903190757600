import Toggle from "@form-elements/Toggle";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { UpgradeSection } from "@/types/util";
import { searchProxy } from "@/store/searchStore";
import { useUserSubscription } from "@/services/payment";
import { useFilterSets } from "@/services/news";

export default function SourceToggle({
    sourceKey,
    label,
    icon,
}: {
    sourceKey: string;
    label: string;
    icon?: JSX.Element;
}) {
    const { showUpgradeModal, setShowUpgradeModal, setUpgradeMessage } = useFilterContext();
    const { filterSet, setFilterSet } = useNewsFeedContext();

    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;
    const { data: filterSets } = useFilterSets();

    const handleChange = () => {
        if (userIsPro) {
            if (filterSet.filterName !== "Saved") {
                // first check the filterset used.
                const activeFilterSetIsUserDefined =
                    filterSets?.some((fil) => fil.filterIndex === filterSet.filterIndex) || false;
                // I used filterName instead of filterIndex before, but I have changed
                // because by using filterName, when filterName changes by virtue of editing, the check comes in as false.

                // if one of the custom ones, you're good.
                // if not, set to customised
                if (!activeFilterSetIsUserDefined) {
                    // If the filterset viewed before click event is not one of the custom ones for the user, switch to customized
                    // If the filter set is not user-defined, switch to "Customized" or the first available filter
                    setFilterSet((prevFilterSet) => {
                        const customized = filterSets?.find((f) => f.filterName === "Customized");
                        return customized ?? (filterSets?.length ? filterSets[0] : prevFilterSet);
                    });
                }
            }
            // then do the below
            setFilterSet((prevFilterSet) => {
                const updatedFilterSet = { ...prevFilterSet, [sourceKey]: !prevFilterSet[sourceKey] };

                if (sourceKey === "showPrimarySourcesOnly") {
                    searchProxy.primarySourcesOnly = !updatedFilterSet[sourceKey];
                }

                return updatedFilterSet;
            });
        } else {
            if (!showUpgradeModal) {
                setUpgradeMessage(UpgradeSection.Filter_Customization);
                // setOpenFilterModal(false);
                setShowUpgradeModal(true);
            }
        }
    };

    const isYouTube = sourceKey === "showYouTubeNews";
    const isBlog = sourceKey === "showBlogNews";
    const isPodcast = sourceKey === "showPodcastNews";

    const isDisabled = isBlog || isYouTube || isPodcast;

    return (
        <div className="flex w-full items-center justify-between gap-2.5 px-0 py-1">
            <div className="flex items-center gap-2">
                {icon && (
                    <div
                        className={`flex size-4 items-center justify-center rounded-full ${
                            isYouTube ? "" : "bg-powder-blue"
                        }`}
                    >
                        {icon}
                    </div>
                )}
                <div className="flex gap-4 text-xs font-normal text-white">
                    <p>{label}</p>
                    {isDisabled && <span className="text-light-blue">Coming Soon</span>}
                </div>
            </div>
            <Toggle checked={filterSet?.[sourceKey] || false} onChange={handleChange} disabled={isDisabled} />
        </div>
    );
}
