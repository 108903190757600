import { useState } from "react";
import { Modal as ReactModal } from "react-responsive-modal";
// import useLocalStorageState from "use-local-storage-state";
import LandingPage from "@app/dashboard/news/LandingPage";
import Customization from "@app/dashboard/news/Customization";
import Top50 from "@app/dashboard/news/Top50";
import Top100 from "@app/dashboard/news/Top100";
import Top200 from "@app/dashboard/news/Top200";
import Top500 from "@app/dashboard/news/Top500";
import Top1000 from "@app/dashboard/news/Top1000";
// import WatchlistFilter from "@app/dashboard/news/WatchlistFilter";
import AllCoins from "@app/dashboard/news/AllCoins";
// import { DeleteWatchlistModal } from "@/components/watchlist/DeleteWatchlistModal";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import { UpgradeModal } from "../news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";

export const FilterModal = ({ handles }: { handles }) => {
    const [twitterUsername, setTwitterUsername] = useState("");
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [redditUsername, setRedditUsername] = useState("");
    const [page, setPage] = useState(CUSTOMIZE.LANDING_PAGE);
    const [tab, setTab] = useState("Twitter");

    const { openFilterModal, setOpenFilterModal } = useNewsFeedContext();
    const { showUpgradeModal, setShowUpgradeModal, upgradeMessage } = useFilterContext();

    const onCloseModal = () => setOpenFilterModal(false);

    const customization_tabs = ["Twitter", "Reddit", "News", "Press Release", "Medium", "Blog"];

    const twitterSources = [
        {
            name: "my sources",
            accounts: handles.mine?.map((h) => ({ name: h, status: true })),
        },
        {
            name: "blockbeat sources",
            accounts: handles.blockbeat?.map((h) => ({ name: h, status: true })),
        },
    ];

    const redditSources = [
        {
            name: "my sources",
            accounts: [
                {
                    name: "r/MDudas",
                    status: true,
                },
                {
                    name: "r/PaikCapital",
                    status: true,
                },
                {
                    name: "r/ErikVoorhees",
                    status: true,
                },
                {
                    name: "r/IvanOnTech",
                    status: true,
                },
                {
                    name: "r/Bybit_Official",
                    status: true,
                },
            ],
        },
        {
            name: "blockbeat sources",
            accounts: [
                {
                    name: "r/Bitcoin",
                    status: true,
                },
                {
                    name: "r/Ethereum",
                    status: true,
                },
                {
                    name: "r/aantonop",
                    status: true,
                },
                {
                    name: "r/Bybit_Official",
                    status: true,
                },
                {
                    name: "r/APompliano",
                    status: true,
                },
                {
                    name: "r/ErikVoorhees",
                    status: false,
                },
                {
                    name: "r/VitalikButerin",
                    status: true,
                },
                {
                    name: "r/IvanOnTech",
                    status: true,
                },
                {
                    name: "r/MessariCrypto",
                    status: false,
                },
                {
                    name: "r/TheCryptoDog",
                    status: true,
                },
                {
                    name: "r/MDudas",
                    status: true,
                },
                {
                    name: "r/PaikCapital",
                    status: true,
                },
                {
                    name: "r/SushiSwap",
                    status: false,
                },
                {
                    name: "r/Saylor",
                    status: true,
                },
                {
                    name: "r/girlgone_crypto",
                    status: true,
                },
            ],
        },
    ];

    return (
        <div>
            {/* <DeleteWatchlistModal
                    index={index}
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    watchlist={currentWatchlist!}
                /> */}

            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={upgradeMessage}
            >
                <></>
            </UpgradeModal>
            <ReactModal
                open={openFilterModal}
                onClose={onCloseModal}
                showCloseIcon={false}
                focusTrapped={false}
                classNames={{
                    modal: "w-100 modal-radius-5 modal-padding-0 modal-margin-0 modal-bg-20242c h-screen max-h-screen",
                    modalContainer: "modal-container-right",
                }}
            >
                {page === CUSTOMIZE.LANDING_PAGE && <LandingPage />}

                {page === CUSTOMIZE.CUSTOMIZATION && (
                    <Customization
                        tab={tab}
                        setTab={setTab}
                        twitterUsername={twitterUsername}
                        setTwitterUsername={setTwitterUsername}
                        redditUsername={redditUsername}
                        setRedditUsername={setRedditUsername}
                        customization_tabs={customization_tabs}
                        setPage={setPage}
                        twitterSources={twitterSources}
                        redditSources={redditSources}
                    />
                )}

                {page === CUSTOMIZE.ALL_COINS && <AllCoins setPage={setPage} />}

                {page === CUSTOMIZE.TOP_50 && <Top50 setPage={setPage} />}

                {page === CUSTOMIZE.TOP_100 && <Top100 setPage={setPage} />}

                {page === CUSTOMIZE.TOP_200 && <Top200 setPage={setPage} />}

                {page === CUSTOMIZE.TOP_500 && <Top500 setPage={setPage} />}

                {page === CUSTOMIZE.TOP_1000 && <Top1000 setPage={setPage} />}

                {/* {page === currentWatchlist?.name && (
                        <WatchlistFilter
                            setShowFilterModal={setOpenFilterModal}
                            setPage={setPage}
                            setShowDeleteModal={setShowDeleteModal}
                            watchlistNameFrom={currentWatchlist?.name}
                            
                        />
                    )} */}
            </ReactModal>
        </div>
    );
};
