import { Modal as ReactModal } from "react-responsive-modal";
import { Button } from "@/components";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useFilterSets, useSaveFilterSetById } from "@/services/news";
import { Dispatch, SetStateAction, useState } from "react";
import Input from "@/components/form-elements/Input";
import { defaultFilterSets, FilterSet } from "@/types/util";
import { concat } from "lodash";

export const ConfirmEditFilterSetModal = ({
    show,
    setShow,
    selectedFilterSet,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    selectedFilterSet: FilterSet;
}) => {
    const [filterSetName, setFilterSetName] = useState(selectedFilterSet.filterName);

    let allFilterSets: FilterSet[] = defaultFilterSets;

    const { data: filterSets } = useFilterSets();
    const { mutate: saveFilterSet } = useSaveFilterSetById();

    if (filterSets) allFilterSets = concat(filterSets, defaultFilterSets);
    const existingNames = allFilterSets.map((f: FilterSet) => f.filterName?.toLowerCase());

    const onCloseModal = () => setShow(false);

    const handleEditFilterSet = async (e) => {
        e.preventDefault();

        if (!filterSetName || existingNames.some((n) => n.toLowerCase() === filterSetName.toLowerCase())) {
            return;
        }
        try {
            setShow(false);
            setFilterSetName("");
            const updatedFilterSet = {
                ...selectedFilterSet,
                filterName: filterSetName,
            };

            saveFilterSet({
                filterSet: updatedFilterSet,
            });
        } catch (e) {
            console.error(e);
            setShow(false);
        }
    };

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            center
            closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
            classNames={{
                modal: "modal-bg-none min-w-100 modal-radius-5 modal-padding-0 modal-margin-0",
            }}
        >
            <div className="w-100 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-base-100 p-0">
                <form className="flex flex-col items-center">
                    <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                        <div className="flex size-9 items-center justify-center rounded-full border border-dark-blue">
                            <FunnelIcon className="size-3.5 text-neutral" strokeWidth={2.5} />
                        </div>
                        <div className="flex w-full flex-col items-center gap-4 px-0 pb-0 pt-2">
                            <h2 className="text-base text-white">Edit FilterSet Name</h2>

                            <Input
                                className="h-10 gap-2 rounded-lg border border-dark-blue bg-steel-blue p-3 text-base text-light-blue"
                                input={filterSetName}
                                setInput={setFilterSetName}
                                placeholder="FilterSet Name"
                            />
                        </div>
                    </div>
                    <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                        <Button
                            onClick={() => setShow(false)}
                            className="w-45 flex h-10 items-center justify-center gap-2.5 rounded-lg border-none bg-royal-blue px-2.5 py-3"
                        >
                            <p className="text-xs font-normal text-white">Close this</p>
                        </Button>
                        <Button
                            type="submit"
                            onClick={handleEditFilterSet}
                            disabled={
                                !filterSetName ||
                                existingNames.some((n) => n.toLowerCase() === filterSetName.toLowerCase())
                            }
                            className="w-45 flex h-10 items-center justify-center gap-2.5 rounded-lg border-none bg-primary px-2.5 py-3"
                        >
                            <p
                                className={`text-xs font-normal ${
                                    !filterSetName ? "text-light-blue" : "text-white"
                                }`}
                            >
                                Save Changes
                            </p>
                        </Button>
                    </div>
                </form>
            </div>
        </ReactModal>
    );
};
