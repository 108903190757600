import { DISPLAY, News } from "@/types/api";
import { concat, isEmpty, isEqual, uniqBy } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { useReadList, useVoteList } from "@/services/news";
import useNews from "@app/dashboard/news-feed/useNews";
import { useToggle } from "react-use";
import {
    FilterSet,
    NewsFeedContextValue,
    activeFilters,
    defaultFilterSets,
    processNewsData,
} from "@/types/util";
import {
    searchByTagsPayload,
    useBookmarkedNews,
    useFetchNewsByIconFilter,
    useSearchNewsByTags,
    useSearchTagsAcrossFields,
} from "@/services/search";
import { filterSetFilter, generateStructuredString } from "@app/dashboard/news-feed/functions";
import { SearchPayload, searchProxy } from "@/store/searchStore";
import { useSnapshot } from "valtio";
import { isBefore, parseISO } from "date-fns";
import { useBookmarks } from "@/services/bookmarks";
import { userProxy } from "@/store/userStore";
import { useUserSubscription } from "@/services/payment";

const NewsFeedContext = createContext<NewsFeedContextValue>({} as NewsFeedContextValue);

const NewsFeedContextProvider: React.FC<any> = ({ children }) => {
    const { data: bookmarks } = useBookmarks();
    const { data: subscription } = useUserSubscription();
    const { realTimeNews } = useNews();
    const { data: readList } = useReadList();
    const { data: vl } = useVoteList();

    const [filterSet, setFilterSet] = useState<FilterSet>(defaultFilterSets[0]);
    const [timeRange, setTimeRange] = useState<number[] | []>([]);

    const [display, setDisplay] = useState<DISPLAY>(DISPLAY.NORMAL);
    const [scrollToTop, setScrollToTop] = useState<boolean>(false);

    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
    const [showSearchNewslist, setShowSearchNewslist] = useState(false);
    const [hideResults, setHideResults] = useToggle(true);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const [newsIndex, setNewsIndex] = useState<number>(0);
    const [fieldsPayload, setFieldsPayload] = useState({} as searchByTagsPayload);
    const [searchQuery, setSearchQuery] = useState([] as string[]);
    const [searchQueryCategory, setSearchQueryCategory] = useState([] as string[]);
    const searchStore = useSnapshot(searchProxy) as SearchPayload;

    const userIsPro = subscription?.subIsActive;
    const neededFilterSet = activeFilters.filter((item) => filterSetFilter(filterSet, item));
    const structuredString = generateStructuredString(...neededFilterSet);
    const encodedUrl = encodeURIComponent(structuredString);
    const filterForLast7Days = userIsPro ? false : true;

    const {
        data: newsByIcon,
        fetchNextPage: fetchNextNewsByIcon,
        hasNextPage: hasNextNewsByIcon,
        isLoading: isLoadingNewsByIcon,
    } = useFetchNewsByIconFilter({
        filter: encodedUrl,
        time: timeRange,
        primarySourcesOnly: filterSet.showPrimarySourcesOnly,
    });

    const {
        data: bookmarkedNews,
        fetchNextPage,
        hasNextPage,
        isLoading: isLoadingBookmarkedNews,
    } = useBookmarkedNews({
        filter: encodedUrl,
        time: timeRange,
        primarySourcesOnly: filterSet.showPrimarySourcesOnly,
    });

    const {
        data: searchedNews,
        fetchNextPage: fetchNextSearchedNews,
        hasNextPage: hasNextSearch,
        isLoading: isSearching,
    } = useSearchNewsByTags(neededFilterSet, {
        ...searchStore,
        primarySourcesOnly: filterSet.showPrimarySourcesOnly,
        time: timeRange,
    });

    const {
        data: searchedTagsAcrossFields,
        fetchNextPage: fetchNextSearchedAcrossTags,
        hasNextPage: hasNextSearchTagsAcrossFields,
        isLoading: isSearchingTagsAcrossFields,
    } = useSearchTagsAcrossFields({
        ...fieldsPayload,
        filter: encodedUrl,
        filterForLast7Days,
    });

    const allNewsByIcon = {
        results: newsByIcon?.pages.flatMap((page) => page.results) || [],
        total: newsByIcon?.pages[0].total,
    };

    const allBookmarkedNews = {
        results: bookmarkedNews?.pages.flatMap((page) => page.results) || [],
        total: bookmarkedNews?.pages[0].total?.value,
    };

    const allNewsByTags = {
        results: searchedNews?.pages.flatMap((page) => page.results) || [],
        total: searchedNews?.pages[0].total,
    };

    const allNewsAcrossFields = {
        results: searchedTagsAcrossFields?.pages.flatMap((page) => page.results) || [],
        total: searchedTagsAcrossFields?.pages[0].total,
    };

    useEffect(() => {
        if (vl) {
            Object.keys(vl).forEach((k) => (userProxy.votes[k] = vl[k]));
        }
    }, [vl]);

    useEffect(() => {
        if (showSearchNewslist && !isEmpty(encodedUrl)) {
            doSearch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSearchNewslist, encodedUrl]);

    let myNews: News[] = [];
    let newsLengthFromSource = 0;
    let newsLoading = false;

    if (showSearchNewslist) {
        if (isEmpty(fieldsPayload)) {
            newsLoading = isSearching;
            myNews = processNewsData(allNewsByTags.results, readList, bookmarks);
            newsLengthFromSource = allNewsByTags.total || 0;
            newsLoading = isSearching;
        } else {
            newsLoading = isSearchingTagsAcrossFields;
            myNews = processNewsData(allNewsAcrossFields.results, readList, bookmarks);
            newsLengthFromSource = allNewsAcrossFields.total || 0;
            newsLoading = isSearchingTagsAcrossFields;
        }
    } else {
        if (filterSet.filterName === "Saved") {
            newsLoading = isLoadingBookmarkedNews;
            myNews = processNewsData(allBookmarkedNews.results, readList, bookmarks);
            newsLoading = isLoadingBookmarkedNews;
            newsLengthFromSource = allBookmarkedNews.total || 0;
        } else {
            newsLoading = isLoadingNewsByIcon;
            myNews = processNewsData(allNewsByIcon.results, readList, bookmarks);
            newsLoading = isLoadingNewsByIcon;
            newsLengthFromSource = allNewsByIcon.total || 0;
        }
        if (realTimeNews.length > 0) {
            if (isEqual(neededFilterSet, activeFilters)) {
                myNews = concat(realTimeNews, myNews);
            }
        }
    }

    // defensive code
    const uniqueNews = uniqBy(myNews as News[], "slug");

    const resultsbeforeToday = uniqueNews.filter((news) => isBefore(parseISO(news.publishedAt), Date.now()));

    // for now, Disable EIN Press as its the wrong feed - requested by Isaac on 23/04/2024
    const renderedResults = resultsbeforeToday.filter((news) => news.source !== "EIN");

    const doSearch = async () => {
        searchProxy.filterForLast7Days = filterForLast7Days;
        // searchProxy.time = timeRange;
        setScrollToTop(true);
        setHideResults(true);
    };

    const loadMoreItems = async () => {
        if (filterSet.filterName === "Saved") {
            if (hasNextPage) fetchNextPage();
        } else {
            if (!showSearchNewslist) {
                if (hasNextNewsByIcon) fetchNextNewsByIcon();
            } else {
                searchProxy.filterForLast7Days = filterForLast7Days;
                if (isEmpty(fieldsPayload)) {
                    if (hasNextSearch) fetchNextSearchedNews();
                } else {
                    if (hasNextSearchTagsAcrossFields) fetchNextSearchedAcrossTags();
                }
            }
        }
    };

    // Add type for value
    const value: NewsFeedContextValue = {
        display,
        setDisplay,
        filterSet,
        setFilterSet,
        openFilterModal,
        setOpenFilterModal,
        scrollToTop,
        setScrollToTop,
        newsIndex,
        setNewsIndex,
        showSearchNewslist,
        setShowSearchNewslist,
        fieldsPayload,
        setFieldsPayload,
        searchQuery,
        setSearchQuery,
        searchQueryCategory,
        setSearchQueryCategory,
        newsLoading,
        hideResults,
        setHideResults,
        loadMoreItems,
        renderedResults,
        timeRange,
        setTimeRange,
        newsLengthFromSource,
        showUpgradeModal,
        setShowUpgradeModal,
    };

    return <NewsFeedContext.Provider value={value}>{children}</NewsFeedContext.Provider>;
};

// Update useNewsFeedContext hook
const useNewsFeedContext = (): NewsFeedContextValue => useContext(NewsFeedContext);

export { useNewsFeedContext, NewsFeedContextProvider };
