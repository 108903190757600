import { useEffect, useState } from "react";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useSelectedLayoutSegment } from "next/navigation";
import { useShortlinkBySlug } from "@/services/news";
import { DISPLAY, News } from "@/types/api";
import { useStytchSession } from "@stytch/nextjs";
import { getArticleSource } from "@/types/util";
import { useCreateBookmarkMutation, useDeleteBookmarkMutation } from "@/services/bookmarks";
import { useUserSubscription } from "@/services/payment";
import { ShareModal } from "@app/dashboard/[articleId]/news-detail/ShareModal";
import Alert from "@/components/Alert";
import { useCopyToClipboard } from "react-use";
import NewsHoverOptions from "./NewsHoverOptions";
import { trackEvent } from "@app/analytics/mixpanel";

export default function NewsListItemHeadline({ article }: { article: News }) {
    const [showAlert, setShowAlert] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [createLink, setCreateLink] = useState(false);

    const [value, copy] = useCopyToClipboard();
    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);
    const newsSource = getArticleSource(article.source);

    const { display, setShowUpgradeModal } = useNewsFeedContext();

    const { mutate: createBookmark } = useCreateBookmarkMutation();
    const { mutate: deleteBookmark } = useDeleteBookmarkMutation();
    const { data: subscription } = useUserSubscription();
    const { session } = useStytchSession();
    const { data } = useShortlinkBySlug(article.slug, createLink);
    const userIsPro = subscription?.subIsActive;

    useEffect(() => {
        if (data?.shortlink && createLink && session) {
            const shareableLink = `https://bb.buzz/s/${data?.shortlink}`;
            trackEvent("Shareable Link Copy", {
                slug: article.slug,
                shortlink: shareableLink,
                user_id: session?.user_id,
            });
            copy(shareableLink);
        }
    }, [data, createLink, session]);

    function addBookmarkFunction() {
        if (!userIsPro) {
            return setShowUpgradeModal(true);
        }
        setAlertMessage("Article Saved");
        setShowAlert(true);
        createBookmark({
            slug: article.slug,
            url: article.url as string,
        });
        trackEvent("Bookmark", {
            slug: article.slug,
            user_id: session?.user_id,
        });
    }

    function deleteBookmarkFunction() {
        if (!userIsPro) {
            return setShowUpgradeModal(true);
        }
        setAlertMessage("Article Unsaved");
        setShowAlert(true);
        deleteBookmark({
            slug: article.slug,
        });
    }

    const onCopyLinkClick = () => {
        setAlertMessage("Article URL copied to clipboard");
        setShowAlert(true);

        if (data?.shortlink) {
            const shareableLink = `https://bb.buzz/s/${data?.shortlink}`;
            trackEvent("Shareable Link Copy", {
                slug: article.slug,
                shortlink: shareableLink,
                user_id: session?.user_id,
            });
            return copy(shareableLink);
        }
        setCreateLink(true);
    };

    return (
        <>
            {showShare && (
                <ShareModal
                    news={article}
                    show={showShare}
                    setShow={setShowShare}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            )}
            {showAlert && (
                <Alert
                    show={showAlert}
                    setShow={setShowAlert}
                    content={alertMessage ?? "Headline copied to clipboard."}
                />
            )}

            <div
                className="group relative flex grow justify-between"
                // TODO: Isaac wants it that when any of the hover items is clicked, the hover does not disappear, which is why I have disabled the onPointerOut
            >
                <p
                    className={`mb-1 line-clamp-2 items-center text-sm font-normal  leading-4 ${
                        isSelected ? "text-white" : article.read ? "text-light-gray" : "text-white"
                    } transition-all duration-1000 ease-out`}
                >
                    {/* some twitter news do not have headlines */}
                    {article.headline}{" "}
                    {display === DISPLAY.THIN && (
                        <span className="text-xs font-light text-neutral">{newsSource}</span>
                    )}
                </p>

                {/* {display !== DISPLAY.THIN && ( */}
                <NewsHoverOptions
                    article={article}
                    setShowAlert={setShowAlert}
                    setShowShare={setShowShare}
                    setAlertMessage={setAlertMessage}
                    onCopyLinkClick={onCopyLinkClick}
                    addBookmarkFunction={addBookmarkFunction}
                    deleteBookmarkFunction={deleteBookmarkFunction}
                />
                {/* )} */}
            </div>
        </>
    );
}
