import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
import youtubeVideoProxy from "@/store/youtube";
import YoutubeVideoModal from "@app/dashboard/[articleId]/news-detail/YoutubeVideoModal";
import { UpgradeSection } from "@/types/util";

const NewsListModals = ({
    showUpgradeModal,
    setShowUpgradeModal,
}: {
    showUpgradeModal: boolean;
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [showYoutubeWidget, setShowYoutubeWidget] = useState(false);
    // const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const youtubeSnapshot = useSnapshot(youtubeVideoProxy);

    useEffect(() => {
        if (youtubeSnapshot.show) {
            if (!showYoutubeWidget) setShowYoutubeWidget(true);
        } else {
            if (showYoutubeWidget) setShowYoutubeWidget(false);
        }
    }, [youtubeSnapshot.show]);

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            {showYoutubeWidget && (
                <YoutubeVideoModal
                    url={youtubeSnapshot.video_url}
                    show={showYoutubeWidget}
                    setShow={setShowYoutubeWidget}
                />
            )}
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Close_Ad}
            >
                <></>
            </UpgradeModal>
        </>
    );
};

export default NewsListModals;
