/* eslint-disable react-hooks/exhaustive-deps */
import { outOfContextProxy, searchProxy } from "@/store/searchStore";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import TagSearchResult from "@app/dashboard/news-feed/Search/TagSearchResult";
import { SearchType } from "@app/dashboard/news-feed/searchType";
import { useStytchSession } from "@stytch/nextjs";
import { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { useSnapshot } from "valtio";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
import { UpgradeSection } from "@/types/util";
import { useUserSubscription } from "@/services/payment";
import { useFilterSets } from "@/services/news";
import SearchBar from "./SearchComponents";

export const Search = () => {
    const {
        filterSet,
        setFilterSet,
        setScrollToTop,
        showSearchNewslist,
        setShowSearchNewslist,
        hideResults,
        setHideResults,
        searchQuery,
        setSearchQuery,
        searchQueryCategory,
        setSearchQueryCategory,
    } = useNewsFeedContext();

    const [input, setInput] = useState("");
    const [debouncedInput, setDebouncedInput] = useState("");
    const [show, setShow] = useState(false);
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const outOfContextSnapshot = useSnapshot(outOfContextProxy);

    const [typeOfNewsSearch, setTypeOfNewsSearch] = useState(SearchType.TAG_FIELD_SEARCH);

    const { session } = useStytchSession();

    const [firstVisit] = useLocalStorageState("firstVisit", {
        defaultValue: Date.now(),
    });
    const { data: filterSets } = useFilterSets();
    const { data: subscription } = useUserSubscription();

    const userIsPro = subscription?.subIsActive;
    const filterForLast7Days = userIsPro ? false : true;

    const LOCKOUT_DURATION_MS = 15000; // 15 seconds

    useEffect(() => {
        if (!session) {
            if (firstVisit) {
                const elapsedTime = Date.now() - firstVisit;
                if (elapsedTime > LOCKOUT_DURATION_MS) {
                    setHideResults(true);
                }
            } else setTimeout(() => setHideResults(true), 15000);
        }
    }, [session, firstVisit]);

    const setKeywordSearch = () => {
        setTypeOfNewsSearch(SearchType.KEYWORD_SEARCH);
        searchProxy.headline = input;
        doSearch();
    };

    const doSearch = async () => {
        if (filterSet.filterName === "Saved") {
            // FIX: Saved filterset isn't really a filterset like the others.
            // rather, it is just a way of saving items.
            // hence, we're setting the filterset as customized here

            const customized = filterSets?.find((f) => f.filterName === "Customized");
            if (customized) setFilterSet(customized);
        }
        searchProxy.filterForLast7Days = filterForLast7Days;
        setScrollToTop(true);
        try {
            if (!showSearchNewslist) setShowSearchNewslist(true);
        } finally {
            setHideResults(true);
        }
    };

    const addSearchItem = (tag: string, category: string) => {
        // if (!userIsPro) {
        //     setShow(true);
        // } else {
        if (searchProxy[category] && !searchProxy[category]?.includes(tag)) {
            searchProxy[category].push(tag);
        }

        if (category === "source") {
            setSearchQueryCategory(["source"]);
            setSearchQuery([tag]);
            setInput("");
            return;
        } else if (searchQuery.length < 2) {
            const allCategories = [...searchQueryCategory];
            const newCategories = [...allCategories, category];
            setSearchQueryCategory([...newCategories]);

            const allItems = [...searchQuery];
            const isAlreadyInList = allItems.some((i) => i === tag);

            if (!isAlreadyInList) {
                const newList = [...allItems, tag];

                setSearchQuery([...newList]);
                return setInput("");
            }
        }
        // }
    };

    useEffect(() => {
        if (outOfContextSnapshot.category && outOfContextSnapshot.tag) {
            addSearchItem(outOfContextSnapshot.tag, outOfContextSnapshot.category);
        }
    }, [outOfContextSnapshot.category, outOfContextSnapshot.tag]);

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={show}
                setShow={setShow}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Search}
            >
                <></>
            </UpgradeModal>
            <SearchBar
                input={input}
                setInput={setInput}
                typeOfNewsSearch={typeOfNewsSearch}
                setKeywordSearch={setKeywordSearch}
                setTypeOfNewsSearch={setTypeOfNewsSearch}
                doSearch={doSearch}
                debouncedInput={debouncedInput}
                setDebouncedInput={setDebouncedInput}
            />

            {typeOfNewsSearch != SearchType.KEYWORD_SEARCH && !hideResults && input.length > 0 && (
                <TagSearchResult
                    input={debouncedInput}
                    setKeywordSearch={setKeywordSearch}
                    setShow={setShow}
                    addSearchItem={addSearchItem}
                />
            )}
        </>
    );
};
