import { useFilterSets } from "@/services/news";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { FunnelIcon } from "@heroicons/react/24/outline";

export function FilterConfigureButton() {
    const { setOpenFilterModal, filterSet } = useNewsFeedContext();

    const { data: filterSets } = useFilterSets();

    const IAmACustomFilterSet = filterSets?.some((f) => f.filterIndex === filterSet.filterIndex);

    const turnButtonBlue = IAmACustomFilterSet;

    return (
        <button
            onClick={() => setOpenFilterModal(true)}
            className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-royal-blue hover:brightness-75"
        >
            <FunnelIcon
                className={`size-3.5 ${turnButtonBlue ? "text-powder-blue" : "text-neutral"}`}
                strokeWidth={2.5}
            />
        </button>
    );
}
