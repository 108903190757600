import Select from "react-select";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { defaultFilterSets, FilterSet } from "@/types/util";
import { useUserSubscription } from "@/services/payment";
import { useFilterSets } from "@/services/news";
import { concat } from "lodash";

const FormatGroupLabel = (data) => (
    <>
        {data.label && (
            <div className="flex w-full items-center justify-between scrollbar-none">
                <span className="w-full border-t border-dark-blue pl-3 pt-1 text-[10px] font-light capitalize leading-3 text-neutral scrollbar-none">
                    {data.label}
                </span>
            </div>
        )}
    </>
);

export function FilterSetSelect() {
    const { filterSet, setFilterSet } = useNewsFeedContext();

    const { data: filterSets } = useFilterSets();

    let allFilterSets: FilterSet[] = defaultFilterSets;

    if (filterSets) allFilterSets = concat(filterSets, defaultFilterSets);

    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;

    let groupedOptions = [
        {
            label: "",
            options: defaultFilterSets,
        },
    ];

    if (userIsPro && filterSets) {
        const filterSetsWithoutCustomized = filterSets?.filter((f) => f.filterName !== "Customized");

        // this is so we don't set the filterset as the "Customized" one, because it is not meant to be seen by the user so they don't feel one has been automatically created for them
        groupedOptions = [
            {
                label: "",
                options: defaultFilterSets,
            },
            {
                label: "Saved Filter Sets",
                options: Object.values(filterSetsWithoutCustomized),
            },
        ];
    }

    const FormatOptionLabel = (data) => (
        <div className="status-options flex w-full cursor-pointer items-center justify-between hover:brightness-75">
            <div className="flex items-center gap-1 overflow-hidden text-ellipsis text-xs font-light">
                {data.icon && (
                    <data.icon
                        strokeWidth={2.5}
                        className={`size-4 ${
                            filterSet?.filterIndex === data.filterIndex ? "text-white" : "text-gray-200"
                        }`}
                    />
                )}
                {!defaultFilterSets
                    .map((item) => item.filterName)
                    .some((item) => item === data.filterName) && (
                    <AdjustmentsHorizontalIcon
                        strokeWidth={2.5}
                        className={`size-4 ${
                            filterSet?.filterIndex === data.filterIndex ? "text-white" : "text-gray-200"
                        }`}
                    />
                )}
                <span
                    className={`truncate ${
                        filterSet.filterIndex === data.filterIndex ? "text-white" : "text-gray-300"
                    } option-name`}
                >
                    {data?.filterName}
                </span>
            </div>
            {filterSet.filterIndex === data.filterIndex && (
                <CheckIcon className="check size-3 text-gray-100" />
            )}
        </div>
    );

    const handleOnChange = (e: any) => {
        const selectedFilterSet = allFilterSets.find((fs: FilterSet) => fs.filterIndex === e.filterIndex);
        if (selectedFilterSet) setFilterSet(selectedFilterSet);
    };

    return (
        <div className="h-8 items-center">
            <Select
                id="filterset-select"
                value={filterSet}
                options={groupedOptions}
                formatGroupLabel={FormatGroupLabel}
                onChange={handleOnChange}
                isSearchable={false}
                formatOptionLabel={FormatOptionLabel}
                styles={filterSetStyles}
                className="filterset-select"
            />
        </div>
    );
}

export const filterSetStyles = {
    singleValue: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 0,
    }),
    group: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    groupHeading: (baseStyles) => ({
        ...baseStyles,
        padding: 0,
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        paddingRight: 0,
        paddingLeft: "6px",
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 0,
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: "16px",
        color: "#a1adc1",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#252932",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#252932",
        right: "-14px",
        borderRadius: "8px",
        border: "1px solid #2f343f",
        width: "140px",
    }),
    indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        color: "#7a869b",
        height: "100%",
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#7a869b",
        height: "100%",
        alignItems: "center",
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "#fff",
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "50px",
        border: "none",
        backgroundColor: "#252932",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        minHeight: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    }),
};
