import MemoCopy from "@assets/SVG/Copy";
import { Dispatch, SetStateAction } from "react";
import { BookmarkIcon, ShareIcon } from "@heroicons/react/24/outline";
import MemoUnread from "@assets/SVG/Unread";
import { useMarkAsUnread } from "@/services/news";
import { News } from "@/types/api";
import MemoLink from "@assets/SVG/Link";
import { BookmarkIcon as BookMarkSolid } from "@heroicons/react/24/solid";
import { useCopyToClipboard } from "react-use";
import { useStytchUser } from "@stytch/nextjs";
import { trackEvent } from "@app/analytics/mixpanel";

const NewsHoverOptions = ({
    article,
    setAlertMessage,
    setShowAlert,
    setShowShare,
    addBookmarkFunction,
    deleteBookmarkFunction,
    onCopyLinkClick,
}: {
    article: News;
    setAlertMessage: Dispatch<SetStateAction<string>>;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    setShowShare: Dispatch<SetStateAction<boolean>>;
    addBookmarkFunction(): void;
    deleteBookmarkFunction(): void;
    onCopyLinkClick: () => void;
}) => {
    const [value, copy] = useCopyToClipboard();
    const { mutate: markAsUnread } = useMarkAsUnread();
    const { user } = useStytchUser();

    return (
        <div
            className="invisible absolute -top-[3px] right-0 z-50 flex h-6 items-center gap-3 rounded-lg border border-deep-blue bg-[#16191F] px-3 py-0 shadow-[0px_4px_16px_rgba(21,24,29,0.25)] group-hover:visible"
            onClick={(e) => e.stopPropagation()}
        >
            {article.read && (
                <div
                    onClick={() => markAsUnread(article.slug)}
                    className="flex size-5 cursor-pointer items-center justify-center hover:brightness-75"
                >
                    <MemoUnread className="size-4" strokeWidth={2} />
                </div>
            )}
            <div
                onClick={() => {
                    copy(article.headline + " - from BlockBeat.io");
                    setAlertMessage("Headline copied to clipboard.");
                    trackEvent("Headline Copy", {
                        headline: article.headline,
                        slug: article.slug,
                        user_id: user?.user_id,
                    });
                    setShowAlert(true);
                }}
                className="flex size-5 cursor-pointer items-center justify-center hover:brightness-75"
            >
                <MemoCopy className="size-4" />
            </div>
            <div className="flex size-5 cursor-pointer items-center justify-center hover:brightness-75">
                {article?.bookmarked ? (
                    <BookMarkSolid onClick={deleteBookmarkFunction} className="size-4 text-neutral" />
                ) : (
                    <BookmarkIcon
                        onClick={addBookmarkFunction}
                        className="size-4 text-neutral"
                        strokeWidth={2.5}
                    />
                )}
            </div>

            <div
                className="flex size-5 cursor-pointer items-center justify-center hover:brightness-75"
                onClick={onCopyLinkClick}
            >
                <MemoLink strokeWidth={2.5} className="size-4 cursor-pointer hover:brightness-75" />
            </div>
            <div
                className="flex size-5 cursor-pointer items-center justify-center hover:brightness-75"
                onClick={() => setShowShare(true)}
            >
                <ShareIcon className="size-4 text-neutral" strokeWidth={2.5} />
            </div>
        </div>
    );
};

export default NewsHoverOptions;
