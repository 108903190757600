import { PlusIcon } from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { UpgradeSection } from "@/types/util";
import { useUserSubscription } from "@/services/payment";
import { useFilterSets } from "@/services/news";
import { ConfirmCloneWatchlistModal } from "../ConfirmCloneWatchlistModal";
import { useState } from "react";

export default function FilterActions() {
    const { filterSet, setFilterSet, setTimeRange } = useNewsFeedContext();
    const { data: filterSets } = useFilterSets();
    const [showConfirmCloneModal, setShowConfirmCloneModal] = useState(false);
    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;

    const {
        setStartDate,
        setEndDate,
        setStartTime,
        setEndTime,
        showUpgradeModal,
        setShowUpgradeModal,
        setUpgradeMessage,
    } = useFilterContext();

    function updateFilterSet(filterName: string) {
        // if (filterName === "No Socials") {
        //     const keys = Object.keys(filterSet);

        //     // Set keys starting with "show" to true
        //     const showKeys = keys.filter((key) => key.startsWith("show"));
        //     showKeys.forEach((key) => (filterSet[key] = true));

        //     // Set keys containing substrings from substringsToSetFalse to false
        //     const substringsToSetFalse = ["Medium", "Twitter", "Reddit", "Podcast", "YouTube"];
        //     showKeys.forEach((key) => {
        //         const shouldSetFalse = substringsToSetFalse.some((substring) => key.includes(substring));
        //         if (shouldSetFalse) {
        //             filterSet[key] = false;
        //         }
        //     });
        // }

        // filterSet["showPrimarySourcesOnly"] = false;

        const FilterSetInUseOriginalState = filterSets?.find((f) => f.filterIndex === filterSet.filterIndex);
        if (FilterSetInUseOriginalState) setFilterSet(FilterSetInUseOriginalState);
    }

    return (
        <>
            {showConfirmCloneModal && (
                <ConfirmCloneWatchlistModal show={showConfirmCloneModal} setShow={setShowConfirmCloneModal} />
            )}
            <div className="flex w-full items-center justify-between gap-4 p-0">
                <div
                    onClick={() => {
                        if (userIsPro) {
                            setStartDate(null);
                            setEndDate(null);
                            setStartTime("");
                            setEndTime("");
                            setTimeRange([]);
                            updateFilterSet(filterSet.filterName);
                            // setOpenFilterModal(false);
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Customization);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                    className="flex items-center gap-0.5"
                >
                    <XCircleIcon className="size-4 text-neutral" />
                    <p className="cursor-pointer text-xs font-normal text-light-blue hover:brightness-75">
                        Reset Filter Set
                    </p>
                </div>
                <div
                    className="flex items-center gap-0.5"
                    onClick={() => {
                        if (userIsPro) {
                            setShowConfirmCloneModal(true);
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Creation);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                >
                    <div className="flex size-4 items-center justify-center">
                        <PlusIcon className="size-3 stroke-2 text-neutral" />
                    </div>
                    <p className="cursor-pointer text-xs font-normal text-light-blue hover:brightness-75">
                        Save as new filter Set
                    </p>
                </div>
            </div>
        </>
    );
}
