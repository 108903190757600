import SourceIcon from "@/components/source/SourceIcon";
import MemoFlame from "@assets/SVG/Flame";
import { useSelectedLayoutSegment } from "next/navigation";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
// import MemoSentimentGraph from "@assets/SVG/Sentiment/SentimentGraph";
import { compact, isEmpty, maxBy, take, uniq, uniqBy } from "lodash";
import { DISPLAY, News } from "@/types/api";
import Vote from "@/components/vote/Vote";
import { SymbolLabel } from "@app/TSWidgets/components/SymbolLabel";
import {
    extractStringsFromArray,
    getArticleSource,
    transformStringsToArrayOfObjects,
    unwantedAssets,
} from "@/types/util";
import NewItemTag from "@app/dashboard/news-feed/news-list/NewsItemTag";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { searchProxy } from "@/store/searchStore";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";

export default function NewsListItemBody({ article }: { article: News }) {
    // const article = useContext(NewsItemContext);
    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);

    const { display, searchQuery, setSearchQuery, searchQueryCategory, setSearchQueryCategory } =
        useNewsFeedContext();

    const compactedAssets = compact([...article.assets.slice(1), article.assets[0]]);
    const filteredAssets = compactedAssets.filter((item) => !unwantedAssets.includes(item.name));
    const mentionedAssets: { name: string; symbol: string }[] = !isEmpty(filteredAssets)
        ? filteredAssets
        : [];

    const extractedStrings = uniq(extractStringsFromArray(article?.tagsData || article?.tags));

    const transformedTags: {
        type: string;
        data: string;
    }[] = compact(transformStringsToArrayOfObjects(extractedStrings));

    const tagsWithType = article.tagsData?.filter((tag) => tag.type);
    let renderedTags = transformedTags;
    if (tagsWithType && !isEmpty(tagsWithType))
        renderedTags = uniqBy([...transformedTags, ...tagsWithType], (tag) => tag?.data?.toLowerCase());

    const newsSource = getArticleSource(article.source);
    const isPressRelease = article.iconType === "Press Release";

    const { negativeSentiment, neutralSentiment, positiveSentiment, data } = article;

    let sentimentIcon;
    let sentimentData;

    if (negativeSentiment && neutralSentiment && positiveSentiment) {
        const highestSentiment = Math.max(negativeSentiment, neutralSentiment, positiveSentiment);

        const highestIsPositive = highestSentiment === positiveSentiment;
        const highestIsNegative = highestSentiment === negativeSentiment;

        sentimentData = {
            positive: positiveSentiment,
            neutral: neutralSentiment,
            negative: negativeSentiment,
        };

        if (highestIsPositive) {
            sentimentIcon = "Positive";
        } else if (highestIsNegative) {
            sentimentIcon = "Negative";
        } else {
            sentimentIcon = "Neutral";
        }
    } else if (data?.sentiment) {
        const { sentiment } = data;

        sentimentData = sentiment;

        if (!isEmpty(sentiment)) {
            const highestSentiment = maxBy(Object.keys(sentiment), (s) => sentiment[s]);

            const highestIsPositive = highestSentiment === "positive";
            const highestIsNegative = highestSentiment === "negative";

            if (highestIsPositive) {
                sentimentIcon = "Positive";
            } else if (highestIsNegative) {
                sentimentIcon = "Negative";
            } else {
                sentimentIcon = "Neutral";
            }
        }
    }

    const addNewItem = (tag: string, category: string) => {
        // if (!userIsPro) {
        //     // props.setShow(true);
        // } else {
        if (searchProxy[category] && !searchProxy[category]?.includes(tag)) {
            searchProxy[category].push(tag);
        }
        if (category === "source") {
            setSearchQueryCategory(["source"]);
            setSearchQuery([tag]);
            // setInput("");
            return;
        } else if (searchQuery.length < 2) {
            const allCategories = [...searchQueryCategory];
            const newCategories = [...allCategories, category];
            setSearchQueryCategory([...newCategories]);

            const allItems = [...searchQuery];
            const isAlreadyInList = allItems.some((i) => i === tag);

            if (!isAlreadyInList) {
                const newList = [...allItems, tag];

                setSearchQuery([...newList]);
                // return setInput("");
            }
        }
        // }
    };

    const isBlockBeat = article?.iconType === "BlockBeat";
    const isTwitter = article?.iconType === "Twitter";

    return (
        <>
            {display === DISPLAY.NORMAL && (
                <div className="relative flex items-center pr-4">
                    <div
                        className="flex cursor-pointer items-center gap-1 hover:brightness-75"
                        onClick={(e) => {
                            e.stopPropagation();
                            addNewItem(newsSource, "sources");
                        }}
                    >
                        {isBlockBeat && isSelected ? (
                            <MemoLogo
                                color="#FFF"
                                className={`${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                } flex size-4 items-center justify-center`}
                            />
                        ) : (
                            <SourceIcon
                                className={`${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                } ${
                                    isTwitter && "rounded-full bg-black"
                                } flex size-4 items-center justify-center`}
                                iconType={article?.iconType}
                            />
                        )}
                        <p
                            className={`${
                                isSelected ? "text-white" : article.read ? "text-light-gray" : "text-neutral"
                            }  max-w-25 cursor-pointer truncate whitespace-nowrap text-xs font-light hover:brightness-75`}
                        >
                            {isTwitter ? `@${article.source}` : newsSource}
                        </p>
                    </div>

                    {!isPressRelease && (!isEmpty(mentionedAssets) || !isEmpty(renderedTags)) && (
                        <>
                            <div className={`mx-2 h-4 w-px ${isSelected ? "bg-[#4170CD]" : "bg-dark-blue"}`}>
                                &nbsp;
                            </div>
                            <div className="flex h-full items-center overflow-x-auto">
                                <div className="flex items-center gap-3">
                                    {mentionedAssets.length > 0 && (
                                        <div className="relative self-center">
                                            <div
                                                className="z-0 m-0 flex cursor-pointer items-center gap-1 hover:brightness-75"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    addNewItem(mentionedAssets[0].symbol, "assets");
                                                }}
                                            >
                                                <CurrencyDollarIcon
                                                    className="size-4"
                                                    color={
                                                        article.read && !isSelected ? "#505A68" : "#D9CC5B"
                                                    }
                                                />
                                                <div
                                                    className={`flex cursor-pointer gap-0.5 whitespace-nowrap text-[10px] font-light leading-3 ${
                                                        article.read && !isSelected
                                                            ? "text-light-gray"
                                                            : "text-light-yellow"
                                                    }`}
                                                >
                                                    <SymbolLabel
                                                        Symbol={mentionedAssets[0]?.symbol}
                                                        renderedName={mentionedAssets[0]?.name}
                                                    />
                                                    {mentionedAssets.length > 1 && (
                                                        <p>
                                                            {" "}
                                                            +
                                                            {mentionedAssets.length - 1 > 2
                                                                ? 2
                                                                : mentionedAssets.length - 1}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isEmpty(renderedTags) && (
                                        <div className="flex gap-1.5 whitespace-nowrap">
                                            {take(renderedTags, 2)?.map((tag, i) => (
                                                <NewItemTag
                                                    tag={tag}
                                                    isSelected={isSelected}
                                                    isRead={article.read}
                                                    key={i}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        addNewItem(tag.data, "tags");
                                                    }}
                                                />
                                            ))}
                                            {renderedTags.length > 2 && (
                                                <div
                                                    className={`text-[10px] font-light leading-3 ${
                                                        isSelected
                                                            ? "text-alice-blue"
                                                            : article.read && !isSelected
                                                              ? "text-light-gray"
                                                              : "text-neutral"
                                                    }`}
                                                >
                                                    +{renderedTags.length - 2}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {article.ai?.hot && (
                        <div className="flex h-full items-center">
                            <MemoFlame
                                className={`size-4 ${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                }`}
                            />
                        </div>
                    )}

                    <div className="flex items-center pr-2">
                        <div className={`mx-2 h-4 w-px ${isSelected ? "bg-[#4170CD]" : "bg-dark-blue"}`}>
                            &nbsp;
                        </div>
                        <div className="flex items-center gap-2">
                            <div
                                className={`z-0 flex items-center gap-0 ${
                                    article.read && !isSelected && "opacity-60"
                                }`}
                            >
                                <Vote decorated={false} />
                            </div>
                        </div>
                    </div>

                    <div className="absolute right-0">
                        {!isEmpty(sentimentData) && (
                            <SentimentPopup sentimentData={sentimentData}>
                                <div className="flex size-4 items-center justify-center">
                                    {sentimentIcon === "Positive" && (
                                        <MemoSmilingEmoji
                                            color={
                                                isSelected
                                                    ? "#FFFFFF"
                                                    : article.read && !isSelected
                                                      ? "#505A68"
                                                      : ""
                                            }
                                        />
                                    )}
                                    {sentimentIcon === "Neutral" && (
                                        <MemoNeutralEmoji
                                            color={
                                                isSelected
                                                    ? "#FFFFFF"
                                                    : article.read && !isSelected
                                                      ? "#505A68"
                                                      : ""
                                            }
                                        />
                                    )}
                                    {sentimentIcon === "Negative" && (
                                        <MemoFrowningEmoji
                                            color={
                                                isSelected
                                                    ? "#FFFFFF"
                                                    : article.read && !isSelected
                                                      ? "#505A68"
                                                      : ""
                                            }
                                        />
                                    )}
                                </div>
                            </SentimentPopup>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
