import TimePicker from "react-time-picker";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { UpgradeSection } from "@/types/util";
import { useUserSubscription } from "@/services/payment";

export const TimeRangePicker = () => {
    const {
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        showUpgradeModal,
        setShowUpgradeModal,
        setUpgradeMessage,
    } = useFilterContext();

    // const { setOpenFilterModal } = useNewsFeedContext();
    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;

    return (
        <div className="flex w-full flex-col items-start gap-2 border-b border-dark-blue px-4 py-3">
            <div className="flex w-full items-start justify-between gap-2 p-0">
                <p className="text-xs font-light text-light-blue">By time</p>
                <p
                    onClick={() => {
                        if (userIsPro) {
                            setStartTime("");
                            setEndTime("");
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Customization);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                    className="cursor-pointer text-xs font-normal text-powder-blue hover:brightness-75"
                >
                    Clear
                </p>
            </div>
            <div className="filter-time-picker flex w-full items-center justify-between gap-2 p-0 text-xs font-light">
                <div className="w-37.5 flex h-10 items-center overflow-hidden rounded-lg border border-dark-blue bg-slate-blue">
                    <TimePicker
                        onChange={(e) => {
                            if (userIsPro) {
                                e && setStartTime(e?.toString());
                            } else {
                                if (!showUpgradeModal) {
                                    setUpgradeMessage(UpgradeSection.Filter_Customization);
                                    // setOpenFilterModal(false);
                                    setShowUpgradeModal(true);
                                }
                            }
                        }}
                        format="h:mm a"
                        value={startTime}
                        clockIcon={null}
                        disableClock={true}
                        clearIcon={null}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                    />
                </div>
                <div className="flex h-px w-3 items-center bg-white" />
                <div className="w-37.5 flex h-10 items-center overflow-hidden rounded-lg border border-dark-blue bg-slate-blue">
                    <TimePicker
                        onChange={(e) => {
                            if (userIsPro) {
                                e && setStartTime(e?.toString());
                            } else {
                                if (!showUpgradeModal) {
                                    setUpgradeMessage(UpgradeSection.Filter_Customization);
                                    // setOpenFilterModal(false);
                                    setShowUpgradeModal(true);
                                }
                            }
                        }}
                        format="h:mm a"
                        value={endTime}
                        clockIcon={null}
                        disableClock={true}
                        clearIcon={null}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                    />
                </div>
            </div>
        </div>
    );
};
