import useMarketStats from "@app/dashboard/useMarketStats";
import { useSpring } from "@react-spring/web";
import {
    MarketCap,
    BitcoinDominance,
    ForecastToday,
    ETHGas,
    FearAndGreed,
    BitcoinHalving,
} from "./MarketStats";

const GlobalMarketStats = () => {
    const [marketStats] = useMarketStats();
    const bitcoinDominance = useSpring(marketStats?.bitcoinDominance);
    const dailySentiment = useSpring(marketStats?.dailySentiment);
    const totalMarketCap = useSpring(marketStats?.totalMarketCap);
    const ethereumGas = useSpring(marketStats?.ethereumGas);
    const fearAndGreed = useSpring(marketStats?.fearAndGreed);
    const bitcoinHalving = useSpring(marketStats?.bitcoinHalving);

    return (
        <div className="flex flex-col">
            <div className="flex items-center border-b border-royal-blue px-2 py-3.5">
                <p className="text-sm font-normal leading-4 text-white">Market Stats</p>
            </div>
            <div className="flex flex-col gap-2 p-2">
                <div className="flex w-full justify-between gap-2">
                    <MarketCap totalMarketCap={totalMarketCap} marketStats={marketStats} />
                    <BitcoinDominance bitcoinDominance={bitcoinDominance} marketStats={marketStats} />
                    <ForecastToday dailySentiment={dailySentiment} marketStats={marketStats} />
                </div>
                <div className="flex w-full justify-between gap-2">
                    <ETHGas ethereumGas={ethereumGas} />
                    <FearAndGreed fearAndGreed={fearAndGreed} />
                    <BitcoinHalving bitcoinHalving={bitcoinHalving} />
                </div>
            </div>
        </div>
    );
};

export default GlobalMarketStats;
