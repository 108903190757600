"use client";

import GlobalMarketStats from "@app/dashboard/GlobalMarketStats";
// import MainMarkets from "@app/dashboard/right-sidebar/MainMarkets";
// import SymbolWatcher from "@app/TSWidgets/SymbolWatcher";
import { NextFont } from "next/dist/compiled/@next/font";
import { vercelEnv } from "@/services/environment";
import HourlyMarketInsights from "@app/TSWidgets/HourlyMarketInsights";
import ComingSoon from "../ComingSoon";

export default function RightSidebar({ font }: { font: NextFont }) {
    // const tags = [
    //     { value: 10000, label: "All" },
    //     { value: 50, label: "Top 50" },
    //     { value: 100, label: "Top 100" },
    //     { value: 250, label: "Top 250" },
    //     { value: 500, label: "Top 500" },
    //     { value: 1000, label: "Top 1000" },
    //     { value: 2500, label: "Top 2500" },
    //     { value: 5000, label: "Top 5000" },
    // ];

    // const [selectedLimit, selectLimit] = useState(50);

    return (
        <aside className="relative hidden h-[calc(100vh-68px)] w-[calc((100vw-32px)/3)] 1xl:block">
            <div className="fixed flex h-[calc(100vh-68px)] w-[inherit] flex-col gap-2">
                <div className="w-full overflow-hidden rounded-lg border border-royal-blue bg-base-100">
                    <GlobalMarketStats />
                </div>
                {/* <HourlyMarketInsights /> */}
                {/* <div className="relative flex grow overflow-hidden">
                    <div className="relative flex w-full grow overflow-hidden rounded-lg border border-royal-blue bg-base-100">
                        <SymbolWatcher font={font} />
                    </div>
                </div> */}
                {vercelEnv !== "production" ? (
                    // <div className="relative flex grow overflow-hidden">
                    // <div className="relative flex w-full grow overflow-hidden rounded-lg border border-royal-blue bg-base-100">
                    //     <SymbolWatcher font={font} />
                    // </div>
                    // </div>
                    <HourlyMarketInsights />
                ) : (
                    <div className="flex grow items-center justify-center overflow-hidden rounded-lg border border-royal-blue bg-base-100">
                        <ComingSoon text="Screener Locked" />
                    </div>
                )}
                {/* <MainMarkets data={mainMarkets} /> */}
            </div>
        </aside>
    );
}
