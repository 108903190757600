import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import InfiniteNewsList from "@app/dashboard/news-feed/news-list/InfiniteNewsList";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { isEmpty } from "lodash";
import { useState } from "react";
import MemoLoading from "@assets/SVG/Loading";
import { useUserSubscription } from "@/services/payment";
import { ResultCountBanner, TiingoAd } from "./NewsListComponents";
import NewsListModals from "./NewsListModals";

const NewsList = () => {
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const { newsLoading, filterSet, showSearchNewslist, renderedResults } = useNewsFeedContext();

    const { data: subscription, isLoading: isLoadingSubscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;
    // I needed to use a different state for either of the upgradeModal states.
    // This was because both modals were rendered at the same time on one of them being toggled.

    const filterSetIsAllowedToHaveBanner =
        filterSet.filterName !== "Customized" && filterSet.filterName !== "All News";
    const bannerIsActive = showSearchNewslist || filterSetIsAllowedToHaveBanner;

    return (
        <div className="flex size-full flex-col overflow-hidden">
            <NewsListModals showUpgradeModal={showUpgradeModal} setShowUpgradeModal={setShowUpgradeModal} />

            {bannerIsActive ? (
                <ResultCountBanner setShowUpgradeModal={setShowUpgradeModal} />
            ) : (
                !isLoadingSubscription && !userIsPro && <TiingoAd setShowUpgradeModal={setShowUpgradeModal} />
            )}

            {newsLoading ? (
                <div className="mt-8 flex justify-center">
                    <MemoLoading className="size-4" />
                </div>
            ) : isEmpty(renderedResults) ? (
                <div className="flex size-full flex-col flex-wrap gap-2 pt-8 align-middle">
                    <ExclamationCircleIcon className="size-8 self-center" />
                    {showSearchNewslist ? (
                        <p className="self-center text-light-gray">No news items matches your search</p>
                    ) : (
                        <p className="self-center text-light-gray">No news items to display</p>
                    )}
                </div>
            ) : (
                <InfiniteNewsList />
            )}
        </div>
    );
};

export default NewsList;
