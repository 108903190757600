import { Modal as ReactModal } from "react-responsive-modal";
import { Dispatch, SetStateAction } from "react";
import MemoYouTubeVideo from "@assets/SVG/YouTubeVideo";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function YoutubeVideoModal({
    url,
    show,
    setShow,
}: {
    url: string;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) {
    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            classNames={{
                modal: "max-h-screen modal-radius-5 modal-padding-0 modal-margin-0",
                modalContainer: "modal-container-right",
            }}
        >
            <div className="w-100 flex items-center justify-center overflow-hidden rounded-lg bg-base-100 p-0">
                <MemoYouTubeVideo videoId={url.split("youtube-")[1]} />
            </div>
        </ReactModal>
    );
}
