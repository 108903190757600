// import { STATUS } from "@/types/api";
import Link from "next/link";
import { usePathname, useSearchParams, useSelectedLayoutSegment } from "next/navigation";
import NewsListItemHeadline from "@app/dashboard/news-feed/news-list/NewsListItemHeadline";
import NewsListItemBody from "@app/dashboard/news-feed/news-list/NewsListItemBody";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";
import Timestamp from "@app/dashboard/news-feed/news-list/Timestamp";
import { useEffect, useState } from "react";
import { prefetchNewsItem, useMarkAsRead } from "@/services/news";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { News } from "@/types/api";
import { useStytchUser } from "@stytch/nextjs";

export default function NewsListItem({ index, article }: { index: number; article: News }) {
    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);
    const { setNewsIndex } = useNewsFeedContext();
    const { mutate: markAsRead } = useMarkAsRead();
    const { user } = useStytchUser();

    useEffect(() => {
        prefetchNewsItem(article.slug);
    }, []);

    useEffect(() => {
        if (isSelected) {
            setNewsIndex(index);
        }
    }, [isSelected]);

    // const isSelectedStyles = isSelected ? "bg-vibrant-blue" : "bg-base-100";

    // const isSelectedStyles = isSelected
    //     ? "bg-vibrant-blue"
    //     : article.status === STATUS.IMPORTANT
    //     ? "important-news-gradient"
    //     : article.status === STATUS.IMPORTANT && article.read
    //     ? "read-important-news-gradiant"
    //     : article.new && !article.read
    //     ? "new-news-gradient"
    //     : "bg-base-100";

    const isSelectedStyles = isSelected ? "bg-vibrant-blue" : "bg-base-100";

    const params = useSearchParams();

    const pathname = usePathname();

    const [count, setCount] = useState(0);

    const barePathname = pathname?.split("/dashboard/")[1];

    useEffect(() => {
        let intervalId = 0;
        if (barePathname === article.slug && count < 4 && !article.read) {
            intervalId = window.setInterval(() => {
                setCount(count + 1);
                if (count === 3) {
                    markAsRead(article.slug);
                    clearInterval(intervalId);
                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, barePathname]);

    let chart;

    if (params) {
        chart = params.get("chart") || "BTC";
    }

    const link = `/dashboard/${article.slug}?chart=${chart || "BTC"}`;

    return (
        <NewsItemContext.Provider value={article}>
            <Link href={link} shallow prefetch legacyBehavior>
                <div className={`static flex h-full cursor-pointer gap-2 px-2 pb-2 pt-2 ${isSelectedStyles}`}>
                    <Timestamp />
                    <div className="flex w-[calc(100%-3.5rem)] flex-col gap-1">
                        <NewsListItemHeadline article={article} />
                        <NewsListItemBody article={article} />
                    </div>
                </div>
            </Link>
        </NewsItemContext.Provider>
    );
}
