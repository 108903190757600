import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { Virtualizer } from "@tanstack/react-virtual";

export default function ScrollToTopButton({
    virtualizer,
}: {
    virtualizer: Virtualizer<HTMLDivElement, Element>;
}) {
    return (
        <div className="absolute top-5 z-10 flex w-full justify-center">
            <div
                className="pointer-events-auto flex h-10 cursor-pointer items-center justify-between gap-2 rounded-3xl bg-powder-blue py-1 pl-1 pr-4 hover:animate-none"
                onClick={() => virtualizer.scrollToIndex(0)}
            >
                <div className="pointer-events-auto flex size-8 items-start gap-2 border-r border-baby-blue p-2">
                    <ArrowUpIcon className="size-4 text-white" />
                </div>
                <div className="flex flex-col items-start justify-center p-0">
                    <p className="text-xs text-white">Back To Top</p>
                </div>
            </div>
        </div>
    );
}
