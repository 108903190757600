import { Asset } from "@/types/api";
import { useQuery } from "@tanstack/react-query";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

const ASSETS_API_URL = isProduction
    ? `https://api-prod.blockbeat.io/assets`
    : `https://api-prod.blockbeat.io/assets`;

// https://stackoverflow.com/questions/894860/set-a-default-parameter-value-for-a-javascript-function
async function fetchAssets(limit = 50): Promise<Asset[]> {
    const res = await fetch(
        `${ASSETS_API_URL}/list?` +
            new URLSearchParams({
                limit: String(limit),
            }),
    );
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useAssets = (limit?: number) => {
    const queryKey = ["assets"];
    return useQuery({
        queryKey,
        queryFn: () => fetchAssets(limit),
    });
};

async function fetchAssetById(symbol: string): Promise<any> {
    const res = await fetch(`${ASSETS_API_URL}/symbol/${symbol}`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useAssetById = (symbol: string) => {
    const queryKey = ["asset", symbol];
    return useQuery({
        queryKey,
        queryFn: () => fetchAssetById(symbol),
    });
};

async function fetchGlobalMetrics(): Promise<any> {
    const res = await fetch(`${ASSETS_API_URL}/metrics`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useGlobalMetrics = () => {
    const queryKey = ["metrics"];
    return useQuery({
        queryKey,
        queryFn: () => fetchGlobalMetrics(),
    });
};

async function fetchMainMarkets(): Promise<any> {
    const res = await fetch(`${ASSETS_API_URL}/markets`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useMainMarkets = () => {
    const queryKey = ["main markets"];
    return useQuery({
        queryKey,
        queryFn: () => fetchMainMarkets(),
    });
};

export async function fetchHistoricalPrice(
    { symbol = "btc", date = "2023-02-10T07:32:43.293Z", units = "usd" } = {} as any,
): Promise<any> {
    const res = await fetch(
        `${ASSETS_API_URL}/tick?` +
            new URLSearchParams({
                symbol: String(symbol),
                time: String(date),
                units: String(units),
            }),
    );
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useHistoricalPrice = ({
    symbol = "btc",
    date = "2023-02-10T07:32:43.293Z",
    units = "usd",
} = {}) => {
    const queryKey = ["historicalPrice", symbol, date, units];
    return useQuery({
        queryKey,
        queryFn: () => fetchHistoricalPrice({ symbol, date, units }),
    });
};

export async function fetchPriceChange({ symbol, from = 0 }) {
    const res = await fetch(
        `${ASSETS_API_URL}/change?` +
            new URLSearchParams({
                symbol: String(symbol.toLowerCase()),
                from: String(from),
            }),
    );
    if (!res.ok) {
        console.error("failed to fetch price chage", symbol);
        return {};
    }
    return res.json();
}

export const usePriceChange = ({ symbol, from = 0 }) => {
    const queryKey = ["priceChange", symbol, from];
    return useQuery({
        queryKey,
        queryFn: () => fetchPriceChange({ symbol, from }),
    });
};

async function getTickData({ symbol, from }): Promise<any[]> {
    const res = await fetch(
        `${ASSETS_API_URL}/chart?` +
            new URLSearchParams({
                symbol: String(symbol.toLowerCase()),
                from: String(from),
            }),
    );
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useTickData = ({ symbol, from }) => {
    const queryKey = ["tickData", symbol, from];
    return useQuery({
        queryKey,
        queryFn: () => getTickData({ symbol, from }),
    });
};
