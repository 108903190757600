import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { InView } from "react-intersection-observer";
import MemoLoading from "@assets/SVG/Loading";
import { Dispatch, SetStateAction, useState } from "react";
import TagResponse from "@app/dashboard/news-feed/Search/TagResponse";
import { getArticleSource } from "@/types/util";
import { isEmpty, uniqBy } from "lodash";
// import { useUserSubscription } from "@/services/payment";
import { useSearchSingleTag } from "@/services/search";

export default function TagSearchResult({
    input,
    setKeywordSearch,
    setShow,
    addSearchItem,
}: {
    input: string;
    setKeywordSearch: () => void;
    setShow: Dispatch<SetStateAction<boolean>>;
    addSearchItem: (tag: string, category: string) => void;
}) {
    const {
        data: tagResult,
        hasNextPage,
        isLoading,
        fetchNextPage,
    } = useSearchSingleTag({
        tag: input,
    });

    const allTagResults = tagResult?.pages.flatMap((page) => page.response) || [];

    const mergedResults = Object.values(
        allTagResults.reduce(
            (acc, item) => {
                if (!acc[item.key]) {
                    // Initialize object if key is not present
                    acc[item.key] = { ...item, top_tags: { hits: { hits: [...item.top_tags.hits.hits] } } };
                } else {
                    // Only merge the `top_tags.hits.hits` array
                    acc[item.key].top_tags.hits.hits.push(...item.top_tags.hits.hits);
                }
                return acc;
            },
            {} as Record<string, { key: string; doc_count: number; top_tags: { hits: { hits: any[] } } }>,
        ),
    );

    const assets: any[] = mergedResults?.filter((item: any) => item.key === "ASSET");
    const sources: any[] = mergedResults?.filter((item: any) => item.key === "SOURCE");
    const companies: any[] = mergedResults?.filter((item: any) => item.key === "ORG");
    const people: any[] = mergedResults?.filter((item: any) => item.key === "PERSON");
    const others: any[] = mergedResults?.filter((item: any) => item.key === "OTHER");

    const assetHits: any[] = uniqBy(assets?.[0]?.top_tags?.hits?.hits, "_id") || [];
    const sourceHits: any[] = uniqBy(sources?.[0]?.top_tags?.hits?.hits, "_id") || [];
    const companiesHits: any[] = uniqBy(companies?.[0]?.top_tags?.hits?.hits, "_id") || [];
    const peopleHits: any[] = uniqBy(people?.[0]?.top_tags?.hits?.hits, "_id") || [];
    // const othersHits: any[] = uniqBy(others?.[0]?.top_tags?.hits?.hits, "_id") || [];

    // this is for assets search, when we have a case where the display name of a source is "Bitcoinist.com" and another is "Bitcoinist".
    // since they are the same, one needs be removed
    const filteredSources = sourceHits.map((item) => {
        const source = getArticleSource(item._source.display);
        return {
            ...item,
            _source: {
                ...item._source,
                display: source,
                tag: source,
            },
        };
    });

    const uniqueCompanies = uniqBy(companiesHits, (item) => item._source.display.toLowerCase());
    const uniqueSources = uniqBy(filteredSources, (item) => item._source.display.toLowerCase());

    const asset_count: number = assets?.[0]?.doc_count;
    const source_count: number = sources?.[0]?.doc_count;
    const companies_count: number = companies?.[0]?.doc_count;
    const people_count: number = people?.[0]?.doc_count;
    // const others_count: number = others?.[0]?.doc_count;

    const tabs = [
        { name: "Coins", count: asset_count || 0 },
        { name: "Sources", count: uniqueSources.length < 10 ? uniqueSources.length : source_count || 0 },
        { name: "People", count: people_count || 0 },
        {
            name: "Companies",
            count: uniqueCompanies.length < 10 ? uniqueCompanies.length : companies_count || 0,
        },
    ]; //, "Topics"];

    const [tab, setTab] = useState(tabs[0]);

    // const { data: subscription } = useUserSubscription();
    // const userIsPro = subscription?.subIsActive;

    return (
        <div
            className="absolute left-0 top-12 z-20 flex h-[370px] w-[calc(100vw-18px)] flex-col overflow-x-hidden overflow-y-scroll scroll-smooth rounded-lg border border-oxford-blue bg-slate-blue px-0 py-[5px] shadow-lg scrollbar scrollbar-track-royal-blue scrollbar-thumb-powder-blue scrollbar-track-rounded-lg scrollbar-w-1 lg:w-[calc((100vw-27px)/2)] 1xl:w-[calc((100vw-37px)/3)]"
            // onPointerLeave={() => setHideResults(true)}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="w-full p-2">
                <button
                    className="btn h-fit min-h-0 rounded-lg border-none bg-powder-blue px-2.5 py-2 normal-case" // onClick={handleAddAssetToWatchlist}
                    onClick={() => {
                        // if (!userIsPro) {
                        //     setShow(true);
                        // } else {
                        setKeywordSearch();
                        // }
                    }}
                >
                    <p className="text-xs font-normal text-white">Search Keywords Only</p>
                </button>
            </div>
            {isLoading ? (
                <div className="my-auto flex justify-center">
                    <MemoLoading className="size-4" />
                </div>
            ) : !isEmpty(tagResult?.pages[0].response) ? (
                <>
                    <nav className="scrollbar-hide mb-2 flex w-full shrink-0 items-center gap-3 overflow-auto border-b border-dark-blue px-2 text-xs font-normal text-neutral">
                        {tabs.map((item, i: number) => {
                            return (
                                <div
                                    className={`flex max-w-[13rem] cursor-pointer items-center gap-1.5 whitespace-nowrap border-powder-blue px-1 py-3.5 font-light hover:brightness-75 ${
                                        tab.name === item.name && `border-b-2 font-normal text-white`
                                    }`}
                                    key={i}
                                    onClick={() => setTab(item)}
                                >
                                    <p>
                                        {item?.name.length > 16
                                            ? item.name.substring(0, 16) + "..."
                                            : item.name}
                                    </p>
                                    <div>{item.count}</div>
                                </div>
                            );
                        })}
                    </nav>
                    <div className="flex flex-col items-start overflow-auto bg-slate-blue px-2 py-0">
                        <TagResponse
                            tab={tab}
                            assetsResults={assetHits}
                            companiesResults={uniqueCompanies}
                            peopleResults={peopleHits}
                            sourcesResults={uniqueSources}
                            keyword={input}
                            addNewItem={addSearchItem}
                        />

                        <InView
                            as="div"
                            className="w-full"
                            onChange={(inView) => {
                                if (inView && hasNextPage) {
                                    fetchNextPage();
                                }
                            }}
                        >
                            <div className="my-2 flex justify-center" />
                        </InView>
                    </div>
                </>
            ) : (
                <div className="my-auto flex flex-col items-center justify-center gap-2 text-light-gray">
                    <ExclamationCircleIcon className="size-8" />
                    <p className="text-light-gray">No match found</p>
                </div>
            )}
        </div>
    );
}
