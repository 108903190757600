import { useFilterSets } from "@/services/news";
import { isEqual, omit } from "lodash";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { useEffect, useRef, useState } from "react";
import { useMap } from "react-use";
import { FilterSet, UpgradeSection } from "@/types/util";
import { useUserSubscription } from "@/services/payment";
import { ConfirmEditFilterSetModal } from "../ConfirmEditFilterSetModal";
import FilterSetPopover from "./FilterSetPopover";
export default function FilterSetSelection() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [filterSetEdited, setFilterSetEdited] = useState(null as unknown as FilterSet);
    const [showOptions, { set: setShowOptions }] = useMap({} as Record<string, boolean>);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const { filterSet, setFilterSet, openFilterModal } = useNewsFeedContext();
    const { showUpgradeModal, setShowUpgradeModal, setUpgradeMessage } = useFilterContext();

    const { data: filterSets } = useFilterSets();
    const { data: subscription } = useUserSubscription();

    const userIsPro = subscription?.subIsActive;
    const renderedFilterSets = filterSets?.filter((f) => f.filterName !== "Customized");

    useEffect(() => {
        if (!openFilterModal) {
            Object.keys(showOptions).forEach((index) => {
                // Set the visibility of delete buttons for other filter sets to false
                setShowOptions(index, false);
            });
        }
    }, [openFilterModal]);

    return (
        <div className="relative flex w-full flex-col items-start gap-2 border-b border-dark-blue px-4 pb-0 pt-3">
            {showEditModal && (
                <ConfirmEditFilterSetModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    selectedFilterSet={filterSetEdited}
                />
            )}
            <p className="text-xs font-light text-light-blue">Use filter set</p>
            <div
                ref={scrollContainerRef}
                className="scrollbar-hide flex w-full items-start gap-1 overflow-x-auto pb-8"
            >
                {renderedFilterSets?.map((f) => {
                    const filterSetWithoutName = omit(filterSet, "filterName");
                    const fWithoutName = omit(f, "filterName");
                    const IHaveBeenMutated = !isEqual(fWithoutName, filterSetWithoutName);
                    // the reason I am comparing them without the names is because the properties is what I want to compare.
                    // because when the name is updated, the original filterset becomes unequal to the mapped one here since the mapped one is a result of the update owing to the fdata fetch from cache.
                    // hence, remove the name so the name is not used to check if they're same.
                    // ANOTHER method would have been to update the filterset after updating, but it causes a rerender on the news feed which I want to avoid.
                    const IAmInUse = f.filterIndex === filterSet.filterIndex;
                    const IShouldBeUpdated = IAmInUse && IHaveBeenMutated;
                    const hasTrueValue = Object.values(showOptions).some((value) => value === true);
                    const IShouldShowUpdateUption = IShouldBeUpdated && !hasTrueValue;

                    return (
                        <div key={f.filterIndex} className="max-w-25 relative">
                            <div
                                onClick={() => {
                                    if (userIsPro) {
                                        setFilterSet(f); //the old/original filterSet
                                    } else {
                                        if (!showUpgradeModal) {
                                            setUpgradeMessage(UpgradeSection.Filter_Creation);
                                            setShowUpgradeModal(true);
                                        }
                                    }
                                }}
                                className={`${
                                    IAmInUse && !IHaveBeenMutated ? "bg-powder-blue" : "bg-slate-blue"
                                } flex h-8 cursor-pointer items-center gap-1 rounded-[50px] border border-dark-blue py-2 pl-3 pr-3 hover:brightness-75`}
                            >
                                <p className="flex items-center truncate text-xs font-light text-white">
                                    {f.filterName}
                                </p>

                                {f.filterName !== "No Socials" && (
                                    // No Socials should not be updated since it is one that all users have.
                                    // make confirmation from Isaac about this
                                    <FilterSetPopover
                                        f={f}
                                        showOptions={showOptions}
                                        IShouldShowUpdateUption={IShouldShowUpdateUption}
                                        setShowOptions={setShowOptions}
                                        setFilterSetEdited={setFilterSetEdited}
                                        setShowEditModal={setShowEditModal}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
