import { DISPLAY } from "@/types/api";
import { Bars2Icon, Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";

export function SwitchDisplayButton() {
    const { display, setDisplay } = useNewsFeedContext();
    const handleNewsDisplay = () => {
        setDisplay((prevDisplay) => (prevDisplay === DISPLAY.NORMAL ? DISPLAY.THIN : DISPLAY.NORMAL));
    };
    return (
        <button
            className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-royal-blue hover:brightness-75"
            onClick={handleNewsDisplay}
        >
            {display === DISPLAY.NORMAL ? (
                <Bars3BottomLeftIcon className="size-4 text-white" />
            ) : (
                <Bars2Icon className="size-4  text-white" />
            )}
        </button>
    );
}
