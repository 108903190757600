import { useCallback, useEffect, useRef, useState } from "react";
import NewsListItem from "@app/dashboard/news-feed/news-list/NewsListItem";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useRouter, useSelectedLayoutSegment } from "next/navigation";
import { DISPLAY, News } from "@/types/api";
// import { fetchNewsItem } from "@/services/news";
import { isEmpty, isEqual, isNumber, last } from "lodash";
import { useVirtualizer } from "@tanstack/react-virtual";
import { InView } from "react-intersection-observer";
import ScrollToTopButton from "@app/dashboard/news-feed/ScrollToTopButton";

const ListItemNoTransition = ({ index, item, isLast }: { index: number; item: News; isLast: boolean }) => {
    const { display } = useNewsFeedContext();

    return (
        <div
            key={item.slug}
            className={`list-item ${display === DISPLAY.NORMAL ? "h-20" : "h-[50px]"} ${!isLast && "border-b border-royal-blue"} hover:brightness-90`}
        >
            <NewsListItem index={index} article={item} />
        </div>
    );
};

const InfiniteNewsList = () => {
    const { scrollToTop, setScrollToTop, newsIndex, renderedResults, loadMoreItems } = useNewsFeedContext();

    const router = useRouter();

    const segment = useSelectedLayoutSegment();

    const parentRef = useRef<HTMLDivElement>(null);

    const virtualizer = useVirtualizer({
        count: renderedResults.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 10000,
        enabled: true,
    });

    const items = virtualizer.getVirtualItems();
    const offset = virtualizer.scrollOffset;
    const [lastKeyPressTime, setLastKeyPressTime] = useState(0);

    // const fetchNewsBySlug = async () => {
    //     const res = await fetchNewsItem(decodeURIComponent(segment as string) as string);
    //     if (!isEmpty(res)) {
    //         // const existingNews = renderedItems;
    //         const newSet = [...renderedItems, res];

    //         const sortedNews = sortBy(newSet, "publishedAt").reverse();

    //         const index = sortedNews.findIndex(
    //             (item: News) => item.slug === decodeURIComponent(segment as string),
    //         );

    //         setRenderedItems([...sortedNews]);
    //         if (index >= 0) {
    //             listRef?.current?._listRef?.scrollToItem(index);
    //         }
    //         setTimeout(() => {
    //             return setNewsItem(res);
    //         }, 1000);
    //     }
    // };

    useEffect(() => {
        // the below happens when users are trying to access a news item from their url.
        // say the user gets a url and wants go go there, and say the item is say 3 days behind and not on the feed.
        if (!isEmpty(segment)) {
            const index = renderedResults.findIndex(
                (item: News) => item.slug === decodeURIComponent(segment as string),
            );
            if (index > -1) {
                virtualizer.scrollToIndex(index);
            }
            // if the newsItem is not on the list
            // fetchNewsBySlug();
        }
    }, []);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key !== "ArrowDown" && e.key !== "ArrowUp") return;

            const currentTime = Date.now();

            // Check if enough time has passed (1 second)
            if (currentTime - lastKeyPressTime < 1000) {
                // If less than 1 second has passed, ignore the keypress
                return;
            }

            const offset = e.key === "ArrowDown" ? 1 : -1;
            const newsOffset = newsIndex + offset;
            const next = renderedResults[newsOffset];

            if (next) {
                virtualizer.scrollToIndex(newsOffset);
                router.push(`/dashboard/${next.SK}`);
            }

            // Update the last key press time
            setLastKeyPressTime(currentTime);
        },
        [newsIndex, renderedResults, virtualizer, router, lastKeyPressTime],
    );

    useEffect(() => {
        if (scrollToTop) {
            virtualizer.scrollToIndex(0);
            setScrollToTop(false);
        }
    }, [scrollToTop]);

    return (
        <div
            className="relative size-full overflow-hidden outline-0 focus:outline-0"
            tabIndex={1}
            onKeyDown={handleKeyDown}
        >
            {isNumber(offset) && offset > 300 && <ScrollToTopButton virtualizer={virtualizer} />}

            <div
                ref={parentRef}
                className="size-full rounded-b-lg"
                style={{
                    overflowY: "auto",
                    contain: "strict",
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => {
                            return (
                                <div
                                    key={virtualRow.key.toString()}
                                    data-index={virtualRow.index}
                                    ref={virtualizer.measureElement}
                                    className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
                                >
                                    <ListItemNoTransition
                                        index={virtualRow.index}
                                        item={renderedResults[virtualRow.index]}
                                        isLast={isEqual(
                                            last(renderedResults),
                                            renderedResults[virtualRow.index],
                                        )}
                                    />
                                </div>
                            );
                        })}
                        <InView
                            as="div"
                            className="w-full"
                            onChange={(inView) => {
                                if (inView) {
                                    loadMoreItems();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfiniteNewsList;
