import { isNumber } from "lodash";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { useEffect } from "react";
import { DateRangePicker } from "./landing/DateRangePicker";
import FilterActions from "./landing/FilterActions";
import FilterSetSelection from "./landing/FilterSetSelection";
import Header from "./landing/Header";
import NewsSources from "./landing/NewsSources";
import ShowResultsButton from "./landing/ShowResultsButton";
import { TimeRangePicker } from "./landing/TimeRangePicker";

export default function LandingPage() {
    const { startTime, startDate, endTime, endDate } = useFilterContext();
    const { setTimeRange, setOpenFilterModal } = useNewsFeedContext();

    useEffect(() => {
        let startDateUTC, endDateUTC;
        if (startDate) {
            const start = new Date(startDate);
            const timeValue = startTime;
            const [hours, minutes] = timeValue.split(":").map(Number);
            if (isNumber(hours) && isNumber(minutes)) {
                start.setHours(hours);
                start.setMinutes(minutes);
            }
            startDateUTC = start.getTime();
        }
        if (endDate) {
            const end = new Date(endDate);
            const timeValue = endTime;
            const [hours, minutes] = timeValue.split(":").map(Number);
            if (isNumber(hours) && isNumber(minutes)) {
                end.setHours(hours);
                end.setMinutes(minutes);
            }
            endDateUTC = end.getTime();
        }

        if (startDateUTC && endDateUTC && startDateUTC < endDateUTC) {
            const timeRange = [startDateUTC, endDateUTC];
            setTimeRange(timeRange);
        }
    }, [startDate, endDate, startTime, endTime]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setOpenFilterModal(false);
            }}
            className="flex h-screen w-full flex-col"
        >
            <Header />
            <div className="scrollbar-hide flex h-screen flex-col items-start overflow-auto p-0">
                <FilterSetSelection />
                <DateRangePicker />
                <TimeRangePicker />
                {/* <NonAssetSpecificNewsToggle /> */}
                <NewsSources />
                {/* <Coins /> */}
            </div>
            <div className="flex w-full flex-col items-center gap-3 border-t border-dark-blue bg-slate-blue px-4 py-3">
                <FilterActions />
                <ShowResultsButton />
            </div>
        </form>
    );
}
