import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import MemoTiingo from "@assets/SVG/Tiingo";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { concat, isEmpty } from "lodash";
import { Dispatch, SetStateAction } from "react";
import MemoLoading from "@assets/SVG/Loading";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import Tooltip from "@/components/Tooltip";
import { defaultFilterSets } from "@/types/util";
import { useFilterSets } from "@/services/news";
import { useUserSubscription } from "@/services/payment";
import { searchProxy } from "@/store/searchStore";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import MemoCryptonews from "@assets/SVG/Cryptonews";
import MemoGavel from "@assets/SVG/Gavel";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoNews from "@assets/SVG/News";
import MemoPodcast from "@assets/SVG/Podcast";
import MemoReddit from "@assets/SVG/Reddit";
import MemoSpeaker from "@assets/SVG/Speaker";
import MemoX from "@assets/SVG/X";
import MemoYouTube from "@assets/SVG/YouTube";
import { BoltIcon, CurrencyDollarIcon, XCircleIcon } from "@heroicons/react/24/outline";

const Icon = ({ sourceKey }: { sourceKey: string }) => {
    let icon = null as any;
    let label = "";

    const [pointer, setPointer] = useState(false);

    switch (sourceKey) {
        case "showBlockbeatNews":
            icon = (
                <div className="flex size-4 items-center justify-center rounded-full bg-powder-blue">
                    <MemoLogo className="h-2.5 w-2" color="#FFFFFF" />
                </div>
            );
            label = "BlockBeat";
            break;
        case "showTwitterNews":
            icon = <MemoX className="size-4 rounded-full bg-black" />;
            label = "Twitter";
            break;
        case "showRedditNews":
            icon = <MemoReddit className="size-4" />;
            label = "Reddit";
            break;
        case "showNewsNews":
            icon = <MemoNews className="size-4" />;
            label = "News";
            break;
        case "showPressRelease":
            icon = <MemoSpeaker className="size-4" />;
            label = "Press Release";
            break;
        case "showGovernmentPress":
            icon = <MemoGavel className="size-4" />;
            label = "Government Press";
            break;
        case "showBlogNews":
            icon = <MemoCryptonews className="size-4" />;
            label = "Blog";
            break;
        case "showYouTubeNews":
            icon = <MemoYouTube className="size-4" />;
            label = "YouTube";
            break;
        case "showPodcastNews":
            icon = (
                <div className="w-full rounded-full bg-powder-blue">
                    <MemoPodcast className="size-4" />
                </div>
            );
            label = "Podcast";
            break;
        case "hideAssetNews":
            icon = <CurrencyDollarIcon className="size-4" />;
            label = "Asset";
            break;
        case "lowQualityNews":
            icon = <BoltIcon className="size-4" />;
            label = "Low Quality";
            break;
        case "showPrimarySourcesOnly":
            icon = <CheckBadgeIcon className="size-4 text-vibrant-blue" />;
            label = "Primary Sources Only";
            break;
    }

    const { filterSet, setFilterSet } = useNewsFeedContext();

    const localFilterSet = {
        ...filterSet,
    };

    if (!icon) return <></>;

    return (
        <Tooltip
            className="tooltip-custom-warning tooltip-bottom flex cursor-pointer items-center justify-center text-xs font-light"
            content={label}
        >
            <div
                onPointerEnter={() => setPointer(true)}
                onPointerLeave={() => setPointer(false)}
                className="flex size-4 items-center justify-center rounded-full"
            >
                {pointer ? (
                    <XCircleIcon
                        onClick={() => {
                            localFilterSet[sourceKey] = !localFilterSet[sourceKey];
                            const isPrimary = sourceKey === "showPrimarySourcesOnly";
                            if (isPrimary) {
                                if (!localFilterSet[sourceKey]) searchProxy.primarySourcesOnly = true;
                                else searchProxy.primarySourcesOnly = false;
                            }

                            setFilterSet(localFilterSet);
                        }}
                    />
                ) : (
                    icon
                )}
            </div>
        </Tooltip>
    );
};

const TiingoAd = ({ setShowUpgradeModal }: { setShowUpgradeModal: Dispatch<SetStateAction<boolean>> }) => {
    return (
        <div className="static flex w-[calc(100%-0.5px)] flex-col border-b border-royal-blue">
            <div className="flex w-full gap-2 bg-base-100 px-2 pb-2 pt-2">
                <div className="flex w-12 flex-col gap-0.5 text-neutral">
                    <p className="whitespace-nowrap text-left text-[10px] font-light leading-3">Ad</p>
                </div>
                <div className="flex w-[calc(100%-3.5rem)] flex-col gap-1">
                    <div className="flex justify-between gap-1 align-top">
                        <p className="text-sm font-normal leading-4 text-white">
                            A Reliable, Enterprise-Grade Financial Markets API Tiingo’s APIs power hedge
                            funds, tech companies, and individuals.
                        </p>
                        <XMarkIcon
                            onClick={() => setShowUpgradeModal(true)}
                            className="size-8 cursor-pointer text-neutral hover:scale-125 hover:brightness-75"
                            strokeWidth={2.5}
                        />
                    </div>
                    <div className="flex gap-1">
                        <MemoTiingo className="h-4 w-[69px]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SearchRestrictionBanner = ({
    setShowUpgradeModal,
}: {
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <div className="flex h-9 w-[calc(100%-2px)] place-content-between items-center gap-2.5 border-b border-royal-blue bg-light-red px-4 py-1">
            <p className="w-auto text-xs font-normal text-white">
                Search History Is Limited to 2 Weeks -{" "}
                <span
                    className="cursor-pointer hover:brightness-75"
                    onClick={() => setShowUpgradeModal(true)}
                >
                    Upgrade Now
                </span>
            </p>
            <XMarkIcon
                onClick={() => setShowUpgradeModal(true)}
                className="size-[16.25px] cursor-pointer text-white hover:scale-125 hover:brightness-75"
                strokeWidth={2.5}
            />
        </div>
    );
};

const HiddenSources = ({ falseAttributes }: { falseAttributes: string[] }) => {
    const { filterSet, setFilterSet } = useNewsFeedContext();

    const { data: filterSets } = useFilterSets();

    const customizedFilterSet = filterSets?.find((f) => f.filterName === "Customized");

    return (
        <div className="flex items-center gap-2">
            {!isEmpty(falseAttributes) && (
                <div className="flex items-center gap-2">
                    <div className="h-4 w-px bg-neutral" />
                    <p className="text-nowrap">Hidden sources:</p>
                    <div className="flex items-center gap-1">
                        {falseAttributes.map((item) => (
                            <Icon key={item} sourceKey={item} />
                        ))}
                    </div>
                    <div className="h-4 w-px bg-neutral" />
                </div>
            )}
            {filterSet.showPrimarySourcesOnly && (
                <>
                    <p className="text-nowrap">Primary Sources Only</p>
                    <div className="mx-2 h-4 w-px shrink-0 bg-neutral" />
                </>
            )}
            <Tooltip
                className="tooltip-custom-warning tooltip-bottom flex cursor-pointer items-center justify-center text-xs font-light"
                content="Clear all Filters"
            >
                <MinusCircleIcon
                    onClick={() => {
                        if (customizedFilterSet) setFilterSet(customizedFilterSet);
                    }}
                    className="size-4 cursor-pointer hover:brightness-75"
                />
            </Tooltip>
        </div>
    );
};

const FilterSetName = () => {
    const { filterSet } = useNewsFeedContext();

    const { data: filterSets } = useFilterSets();

    const savedFilterSet = defaultFilterSets.filter((f) => f.filterName === "Saved");

    const filterSetsThatCanUseBanner = concat(filterSets || [], savedFilterSet);

    const filterSetInUseUpdatedState = filterSetsThatCanUseBanner?.find(
        (f) => f.filterIndex === filterSet.filterIndex,
    );
    // the reason for this is because when the filterset gets updated, I am not updating the filterset in use across board,
    // because it causes a rerendering of the news feed, which we want to limit as much as possible.
    // but by fetching the name directly from the latest state from the cache, I am guaranteed the name.

    return (
        <div className="flex items-center gap-2">
            <span className="truncate">Filter Set: {filterSetInUseUpdatedState?.filterName}&nbsp;</span>
        </div>
    );
};

const ResultCountBanner = ({
    setShowUpgradeModal,
}: {
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const { newsLoading, filterSet, showSearchNewslist, newsLengthFromSource } = useNewsFeedContext();

    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;

    const falseAttributes = Object.entries(filterSet)
        .filter(([key, value]) => typeof value === "boolean" && value === false)
        .map(([key, value]) => key)
        .filter(
            (item) =>
                item !== "hideAssetNews" && item !== "lowQualityNews" && item !== "showPrimarySourcesOnly",
        );

    return (
        <div className="flex w-[calc(100%-2px)] flex-col">
            <div className="flex w-full items-center justify-between gap-2.5 border-b border-royal-blue bg-slate-blue p-3 text-[10px] font-light leading-3 text-neutral">
                <div>
                    {filterSet.filterName !== "All News" && (
                        // this is so we don't set the filterset as the "Customized" one, because it is not meant to be seen by the user so they don't feel one has been automatically created for them
                        <div className="flex items-center gap-2">
                            {filterSet.filterName !== "Customized" && <FilterSetName />}
                            {(!isEmpty(falseAttributes) || filterSet.showPrimarySourcesOnly) && (
                                <HiddenSources falseAttributes={falseAttributes} />
                            )}
                        </div>
                    )}
                </div>

                {newsLoading ? (
                    <div className="">
                        <MemoLoading className="size-3" />
                    </div>
                ) : (
                    <div className="shrink-0">
                        results: {newsLengthFromSource === 10000 ? "9999+" : newsLengthFromSource || 0}
                    </div>
                )}
            </div>
            {showSearchNewslist && !userIsPro && (
                <SearchRestrictionBanner setShowUpgradeModal={setShowUpgradeModal} />
            )}
        </div>
    );
};

export { TiingoAd, ResultCountBanner };
