import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/dashboard/BlockBeatBetaLogo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/dashboard/CountdownClock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardLink"] */ "/vercel/path1/app/dashboard/DashboardLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/dashboard/news-feed/NewsFeed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/dashboard/right-sidebar/RightSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/SessionControls.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/dashboard/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"lato\"}");
