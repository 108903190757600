import MemoX from "@assets/SVG/X";
import SourceIcon from "@/components/source/SourceIcon";
import { BuildingOfficeIcon, ExclamationCircleIcon, UserIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import { getArticleSource } from "@/types/util";

export default function TagResponse({
    tab,
    keyword,
    addNewItem,
    assetsResults,
    companiesResults,
    peopleResults,
    sourcesResults,
}: {
    tab: {
        name: string;
        count: number;
    };
    keyword: string;
    addNewItem: any;
    assetsResults: any[];
    companiesResults: any[];
    peopleResults: any[];
    sourcesResults: any[];
}) {
    let tabData;

    if (tab.name === "Coins") tabData = assetsResults;
    if (tab.name === "Companies") tabData = companiesResults;
    if (tab.name === "People") tabData = peopleResults;
    if (tab.name === "Sources") tabData = sourcesResults;

    return (
        <div className="flex w-full flex-col items-start p-0">
            {tabData.length > 0 ? (
                <div className="col-span-2 grid w-full grid-cols-2 gap-2">
                    {tabData?.map((value: any) => {
                        const regex = new RegExp(`(${keyword})`, "gi");
                        const source = value?._source;
                        const tag = source?.tag;
                        const symbol = source?.symbol;
                        const tagParts = tag?.split(regex);
                        const symbolParts = symbol?.split(regex);

                        const highlightedTag = tagParts?.map((part, index) =>
                            regex.test(part) ? (
                                <span key={index} className="text-powder-blue">
                                    {part}
                                </span>
                            ) : (
                                part
                            ),
                        );

                        const highlightedSymbol = symbolParts?.map((part, index) =>
                            regex.test(part) ? (
                                <span key={index} className="text-powder-blue">
                                    {part}
                                </span>
                            ) : (
                                part
                            ),
                        );

                        let highlightedText, iconType;
                        // Companies

                        if (tab.name === "Companies") {
                            const parts = source.display.split(regex);
                            highlightedText = parts.map((part, index) =>
                                regex.test(part) ? (
                                    <span key={index} className="text-powder-blue">
                                        {part}
                                    </span>
                                ) : (
                                    part
                                ),
                            );
                        }

                        // People
                        if (tab.name === "People") {
                            const parts = tag?.split(regex);
                            highlightedText = parts?.map((part, index) =>
                                regex.test(part) ? (
                                    <span key={index} className="text-powder-blue">
                                        {part}
                                    </span>
                                ) : (
                                    part
                                ),
                            );
                        }

                        // Sources
                        if (tab.name === "Sources") {
                            const display = source?.display;
                            const refinedSourced = getArticleSource(display);
                            iconType = source?.iconType;
                            const parts = refinedSourced?.split(regex);
                            highlightedText = parts?.map((part, index) =>
                                regex.test(part) ? (
                                    <span key={index} className="text-powder-blue">
                                        {part}
                                    </span>
                                ) : (
                                    part
                                ),
                            );
                        }

                        function customCapitalize(str: string): string {
                            if (str.includes(".")) {
                                const words = str.split(".");
                                const capitalizedWords = words.map((word, index) => {
                                    if (index === 0) {
                                        // Capitalize the first word
                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                    } else {
                                        return word;
                                    }
                                });

                                return capitalizedWords.join(".");
                            } else {
                                // If the string doesn't contain a period, return it as is
                                return str;
                            }
                        }

                        return tab.name === "Coins" ? (
                            <div
                                key={value?._id}
                                onClick={() => addNewItem(symbol, "assets")}
                                className="flex w-full cursor-pointer items-center gap-1 px-1 py-2 hover:brightness-75"
                            >
                                <Image
                                    className="size-4 shrink-0 rounded-full"
                                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${source.assetId}.png`}
                                    alt={`${symbol}-symbol`}
                                    width={64}
                                    height={64}
                                />

                                <p className="overflow-wrap text-xs font-light capitalize text-white">
                                    {highlightedTag}
                                </p>
                                <p className="text-xs font-light text-neutral">{highlightedSymbol}</p>
                            </div>
                        ) : tab.name === "Companies" ? (
                            <div
                                key={value._id}
                                onClick={() => addNewItem(tag, "tags")}
                                className="flex w-full cursor-pointer items-center gap-1 px-1 py-2 hover:brightness-75"
                            >
                                <BuildingOfficeIcon
                                    className="size-4 shrink-0 text-light-gray"
                                    strokeWidth={2}
                                />
                                <p className="overflow-wrap text-xs font-light capitalize text-white">
                                    {highlightedText}
                                </p>
                            </div>
                        ) : tab.name === "People" ? (
                            <div
                                key={value?._id}
                                onClick={() => addNewItem(tag, "tags")}
                                className="flex w-full cursor-pointer items-center gap-1 px-1 py-2 hover:brightness-75"
                            >
                                <UserIcon strokeWidth={2} className="size-4 shrink-0 text-light-gray" />
                                <p className="overflow-wrap text-xs font-light capitalize text-white">
                                    {highlightedText}
                                </p>
                            </div>
                        ) : (
                            tab.name === "Sources" && (
                                <div
                                    key={value._id}
                                    onClick={() => addNewItem(tag, "sources")}
                                    className="flex w-full cursor-pointer items-center gap-1 px-1 py-2 hover:brightness-75"
                                >
                                    {iconType === "Twitter" ? (
                                        <MemoX className="size-4 shrink-0 rounded-full bg-black" />
                                    ) : (
                                        <SourceIcon className="size-4 shrink-0" iconType={iconType} />
                                    )}
                                    {source.display.includes(".") ? (
                                        <p className={`overflow-wrap truncate text-xs font-light text-white`}>
                                            {customCapitalize(highlightedText)}
                                        </p>
                                    ) : (
                                        <p
                                            className={`overflow-wrap truncate text-xs font-light capitalize text-white`}
                                        >
                                            {highlightedText}
                                        </p>
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
            ) : (
                <div className="mt-12 flex flex-col items-center justify-center gap-2 self-center text-light-gray">
                    <ExclamationCircleIcon className="size-8" />
                    <p className="text-light-gray">No match found</p>
                </div>
            )}
        </div>
    );
}
