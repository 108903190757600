import { useEffect } from "react";
import { useMap } from "react-use";
import { useEventsContext } from "@app/dashboard/EventsContext";
import { clamp } from "lodash";
import { useGlobalMetrics } from "@/services/assets";

function generatePercentage(number) {
    try {
        return clamp(number, -1, 1) * 100;
    } catch (error) {
        return 0;
    }
}

const useMarketStats = () => {
    const socket = useEventsContext();
    const { data: globalMetrics } = useGlobalMetrics();

    const startDate = new Date("04/27/2020"); // This date should be set to the date when the countdown from 1430 starts
    const today = new Date();
    // Calculate the total days passed since the start date
    const timeDifference = today.getTime() - startDate.getTime();
    const daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24));
    // Settings for the countdown
    const cycleLength = 1430; // Total days in the cycle
    // Calculate current day in the cycle
    let daysUntilHalving = (cycleLength - (daysPassed % cycleLength)) % cycleLength;
    // If it's the zero day, reset to the cycle length
    if (daysUntilHalving === 0) {
        daysUntilHalving = cycleLength;
    }

    const [data, { set }] = useMap({
        totalMarketCap: {
            current: 0,
            change: 0,
        },
        bitcoinDominance: {
            current: 0,
            change: 0,
        },
        dailySentiment: {
            score: 0,
            emoji: "",
            highestSentiment: "",
            length: 0,
        },
        ethereumGas: {},
        fearAndGreed: {},
        bitcoinHalving: { current: daysUntilHalving },
    });

    useEffect(() => {
        if (globalMetrics) {
            set("totalMarketCap", {
                current: Number(globalMetrics?.info.Item?.data.quote.USD.total_market_cap),
                change: Number(
                    globalMetrics?.info.Item?.data.quote.USD.total_market_cap_yesterday_percentage_change,
                ),
            });
            set("bitcoinDominance", {
                current: Number(globalMetrics?.info.Item?.data.btc_dominance),
                change: Number(globalMetrics?.info.Item?.data.btc_dominance_24h_percentage_change),
            });
            set("dailySentiment", {
                score: generatePercentage(globalMetrics?.dailySentiment?.Item?.data.score),
                emoji: globalMetrics?.dailySentiment?.Item?.data.emoji,
                highestSentiment: globalMetrics?.dailySentiment?.Item?.data.highestSentiment,
                length: globalMetrics?.dailySentiment?.Item?.data.length,
            });
            set("ethereumGas", globalMetrics?.ethGas.Item?.data);
            set("fearAndGreed", globalMetrics?.fearAndGreed.Item?.data);
        }
    }, [globalMetrics]);

    useEffect(() => {
        if (socket.connected) {
            socket.on("market-stats", function (msg) {
                set("totalMarketCap", {
                    current: Number(msg.quote.USD.total_market_cap),
                    change: Number(msg.quote.USD.total_market_cap_yesterday_percentage_change.toFixed(2)),
                });
                set("bitcoinDominance", {
                    current: Number(msg.btc_dominance),
                    change: Number(msg.btc_dominance_24h_percentage_change.toFixed(2)),
                });
            });
            socket.on("eth-gas", function (msg) {
                set("ethereumGas", msg);
            });
            socket.on("fear-and-greed", function (msg) {
                set("fearAndGreed", msg);
            });
            socket.on("daily-sentiment", function (msg) {
                set("dailySentiment", {
                    score: generatePercentage(msg.score),
                    emoji: msg.emoji,
                    highestSentiment: msg.highestSentiment,
                    length: msg.length,
                });
            });
        }
    }, [socket]);

    return [data];
};

export default useMarketStats;
