import { FunctionComponent, memo } from "react";

type Props = {
    videoId: string;
};

const YouTubeVideo: FunctionComponent<Props> = ({ videoId }) => {
    return (
        <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
    );
};

const MemoYouTubeVideo = memo(YouTubeVideo);
export default MemoYouTubeVideo;
