import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { useEffect } from "react";
import { UpgradeSection } from "@/types/util";
import { useUserSubscription } from "@/services/payment";

export const DateRangePicker = () => {
    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        showUpgradeModal,
        setShowUpgradeModal,
        setUpgradeMessage,
    } = useFilterContext();

    const { setTimeRange } = useNewsFeedContext();
    const { data: subscription } = useUserSubscription();
    const userIsPro = subscription?.subIsActive;

    useEffect(() => {
        // This is incase the user selects start an end date earlier than a start date
        if (startDate && endDate) {
            if (startDate > endDate) {
                const previousDate = new Date(endDate);
                previousDate.setDate(previousDate.getDate() - 1);

                setStartDate(previousDate);
            }
        }
    }, [startDate, endDate]);

    return (
        <div className="flex w-full flex-col items-start gap-2 border-b border-dark-blue px-4 py-3">
            <div className="flex w-full items-start justify-between gap-2 p-0">
                <p className="text-xs font-light text-light-blue">By date</p>
                <p
                    onClick={() => {
                        if (userIsPro) {
                            setStartDate(null);
                            setEndDate(null);
                            setTimeRange([]);
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Customization);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                    className="cursor-pointer text-xs font-normal text-powder-blue hover:brightness-75"
                >
                    Clear
                </p>
            </div>
            <div className="filter-date-picker flex w-full items-center justify-between gap-2 p-0">
                <div className="relative">
                    <div className="w-37.5 flex h-10 items-center">
                        <DatePicker
                            selected={startDate}
                            onChange={(date: Date) => {
                                if (userIsPro) {
                                    setStartDate(date);
                                } else {
                                    if (!showUpgradeModal) {
                                        setUpgradeMessage(UpgradeSection.Filter_Customization);
                                        // setOpenFilterModal(false);
                                        setShowUpgradeModal(true);
                                    }
                                }
                            }}
                            className="h-10 w-full rounded-lg border-dark-blue bg-slate-blue py-2 pl-8 text-xs font-light text-white"
                            placeholderText="DD/MM/YYYY"
                            maxDate={endDate ? endDate : new Date()}
                        />
                    </div>
                    <MagnifyingGlassIcon className="absolute bottom-0 left-2.5 top-0 m-auto size-4 text-neutral" />
                </div>
                <div className="flex h-px w-3 items-center bg-white text-center text-xs font-light" />
                <div className="relative">
                    <div className="w-37.5 flex h-10 items-center">
                        <DatePicker
                            selected={endDate}
                            onChange={(date: Date) => {
                                if (userIsPro) {
                                    setEndDate(date);
                                } else {
                                    if (!showUpgradeModal) {
                                        setUpgradeMessage(UpgradeSection.Filter_Customization);
                                        // setOpenFilterModal(false);
                                        setShowUpgradeModal(true);
                                    }
                                }
                            }}
                            className="h-10 w-full rounded-lg border-dark-blue bg-slate-blue py-2 pl-8 text-xs font-light text-white"
                            placeholderText="DD/MM/YYYY"
                            maxDate={new Date()}
                            minDate={startDate}
                        />
                    </div>
                    <MagnifyingGlassIcon className="absolute bottom-0 left-2.5 top-0 m-auto size-4 text-neutral" />
                </div>
            </div>
        </div>
    );
};
