import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import AssetIcon from "@/components/asset/AssetIcon";
import { Dispatch, SetStateAction } from "react";
import { useAssets } from "@/services/assets";

export default function Top1000({ setPage }: { setPage: Dispatch<SetStateAction<CUSTOMIZE>> }) {
    const { data: assets } = useAssets();

    return (
        <div className="flex h-screen w-full flex-col">
            <div className="flex w-full flex-col items-start border-b border-dark-blue px-4 py-3">
                <div className="flex items-center gap-2">
                    <ArrowLeftIcon
                        className="size-4 cursor-pointer text-neutral hover:brightness-75"
                        onClick={() => setPage(CUSTOMIZE.LANDING_PAGE)}
                    />
                    <h1 className="text-base font-normal leading-5 text-white">Top 1000 Coins</h1>
                </div>
            </div>
            <div className="scrollbar-hide flex h-screen flex-col items-start overflow-auto bg-slate-blue px-4 py-0">
                <div className="flex w-full flex-col items-start gap-3 px-0 py-3">
                    <p className="text-xs font-light text-light-blue">List of top 1000 coins</p>
                    <div className="flex w-full flex-col items-start gap-2 p-0">
                        {assets?.map((asset, i) => {
                            const localAsset = asset.data;
                            return (
                                <Link
                                    href={{
                                        pathname: `/assets/${localAsset?.["Source URL"]}`,
                                    }}
                                    key={i}
                                >
                                    <div className="flex w-full items-center gap-1 px-0 py-0.5">
                                        <AssetIcon className="size-4" acronym={localAsset?.["Ticker"]} />
                                        <p className="text-xs font-normal text-white">
                                            {localAsset?.["Coin Name"]}
                                        </p>
                                        <p className="text-xs font-light uppercase text-neutral">
                                            {localAsset?.["Ticker"]}
                                        </p>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
