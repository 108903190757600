/* eslint-disable react-hooks/exhaustive-deps */
import { clearOutOfContextProxy, clearSearchProxy, searchProxy } from "@/store/searchStore";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import MemoSearchTags from "@assets/SVG/SearchTags";
import { MagnifyingGlassIcon, PlusIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { SearchType } from "./searchType";
import { searchByTagsPayload } from "@/services/search";
import { debounce, isEmpty } from "lodash";
import { useStytchUser } from "@stytch/nextjs";
import { useSnapshot } from "valtio";
import { trackEvent } from "@app/analytics/mixpanel";

const SearchBar = ({
    input,
    setInput,
    setKeywordSearch,
    typeOfNewsSearch,
    setTypeOfNewsSearch,
    doSearch,
    debouncedInput,
    setDebouncedInput,
}: {
    input: string;
    setInput: Dispatch<SetStateAction<string>>;
    setKeywordSearch: () => void;
    typeOfNewsSearch: SearchType;
    setTypeOfNewsSearch: Dispatch<SetStateAction<SearchType>>;
    doSearch: () => Promise<void>;
    debouncedInput: string;
    setDebouncedInput: Dispatch<SetStateAction<string>>;
}) => {
    const {
        setScrollToTop,
        showSearchNewslist,
        setShowSearchNewslist,
        fieldsPayload,
        setFieldsPayload,
        searchQuery,
        setSearchQuery,
        searchQueryCategory,
        setSearchQueryCategory,
    } = useNewsFeedContext();

    const { user } = useStytchUser();

    const searchStore = useSnapshot(searchProxy);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // Focus on the input element when the component mounts
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current, searchQuery[0]]);

    useEffect(() => {
        const searchItems = Object.fromEntries(
            Object.entries({
                assets: [...searchStore.assets],
                sources: [...searchStore.sources],
                tags: [...searchStore.tags],
            }).filter(([_, value]) => value.length > 0), // Remove keys with empty arrays
        );

        if (searchQuery?.[0] || searchQuery?.[1]) {
            trackEvent("News Search", {
                search_input: searchItems,
                user_id: user?.user_id,
            });

            if (!showSearchNewslist) {
                setShowSearchNewslist(true);
            }
            setInput("");
        } else {
            setShowSearchNewslist(false);
        }
    }, [searchQuery?.[0], searchQuery?.[1]]);

    const clearSearch = () => {
        setInput("");
        setTypeOfNewsSearch(SearchType.TAG_FIELD_SEARCH);
        setSearchQueryCategory([]);
        setShowSearchNewslist(false);
        clearSearchProxy();
        setSearchQuery([]);
        if (!isEmpty(fieldsPayload) && showSearchNewslist) {
            setFieldsPayload({} as searchByTagsPayload);
        }
        setScrollToTop(true);
    };

    const addNewItem = (e: any) => {
        if (e.key === "Enter") {
            setKeywordSearch();
        } else if (e.target.value == "" && searchQuery.length == 0) {
            // If the user removes all text from the search input and the search has no
            // source, people, tags selection, the search resets back to default mode.
            clearSearch();
        }
    };

    const removeItem = (i: number) => {
        clearOutOfContextProxy();
        const item = searchQuery[i];
        ["assets", "sources", "tags"].forEach((attr) => {
            const idx = searchProxy[attr].indexOf(item);
            if (idx != -1) {
                searchProxy[attr].splice(idx, 1);
            }
        });

        searchQuery.splice(i, 1);
        searchQueryCategory.splice(i, 1);

        setSearchQuery([...searchQuery]);
        setSearchQueryCategory([...searchQueryCategory]);
    };

    const runSearch = (text: string) => {
        searchProxy.headline = text;
        doSearch();
    };

    const debounceSearch = useRef(debounce(runSearch, 300));

    useEffect(() => {
        const handler = debounce((value) => setDebouncedInput(value), 500);
        handler(input);

        return () => handler.cancel(); // Cleanup function to prevent unnecessary updates
    }, [input]);

    useEffect(() => {
        if (!isEmpty(debouncedInput)) {
            trackEvent(`Keyword Search`, {
                search_input: debouncedInput,
                user_id: user?.user_id,
            });
        }

        if (typeOfNewsSearch !== SearchType.KEYWORD_SEARCH) return;
        debounceSearch.current(debouncedInput);
    }, [debouncedInput]);

    return (
        <div
            className="relative flex w-[calc(100%-40px)] rounded-[50px] bg-royal-blue px-2"
            id="searchbox"
            onClick={(e) => e.stopPropagation()}
        >
            <MagnifyingGlassIcon className="m-2 size-4 shrink-0 text-neutral" />
            <div
                className={`flex h-8 w-[calc(100%-48px)] gap-1 pl-2 ${(!!searchQuery?.[1] || !!input) && "pr-2"}`}
            >
                {/* <div className={`flex gap-2 overflow-auto ${!searchQuery[0] && "w-full"}`}> */}
                {/* I don't remember why I had the above, but I have removed and testing with the new one now. Let's see what happens */}
                <div className="flex w-full gap-2 overflow-auto">
                    {searchQuery[0] && (
                        <div
                            className={`flex ${
                                searchQuery?.[1] ? "mr-6 w-full" : "w-auto"
                            } items-center gap-2`}
                        >
                            {searchQuery[0] && <FirstSearchQuery removeItem={removeItem} />}
                            {searchQuery[0] && <AndOr />}
                            {searchQuery[1] && <SecondSearchQuery removeItem={removeItem} />}
                        </div>
                    )}
                    {!searchQuery[1] && (
                        <SearchInput
                            addNewItem={addNewItem}
                            input={input}
                            inputRef={inputRef}
                            setInput={setInput}
                        />
                    )}
                </div>
            </div>

            {(input || searchQuery.length > 0) && (
                <XCircleIcon
                    onClick={() => clearSearch()}
                    className="m-auto size-4 shrink-0 cursor-pointer text-neutral hover:brightness-75"
                />
            )}
        </div>
    );
};

const FirstSearchQuery = ({ removeItem }: { removeItem: (i: number) => void }) => {
    const { searchQuery, setShowSearchNewslist } = useNewsFeedContext();
    return (
        <div className="flex items-center gap-1 rounded-2xl bg-dark-blue px-2 py-1">
            <MemoSearchTags className="size-4" />
            <div className="truncate text-xs text-white">{searchQuery[0]}</div>
            <XMarkIcon
                className="size-3 cursor-pointer text-neutral hover:brightness-75"
                strokeWidth={4}
                onClick={() => {
                    removeItem(0);
                    if (!searchQuery[1]) setShowSearchNewslist(false);
                }}
            />
        </div>
    );
};

const SecondSearchQuery = ({ removeItem }: { removeItem: (i: number) => void }) => {
    const { searchQuery } = useNewsFeedContext();

    return (
        <div className="flex items-center gap-1 rounded-2xl bg-dark-blue px-2 py-1">
            <MemoSearchTags className="size-4" />
            <div className="truncate text-xs text-white">{searchQuery[1]}</div>
            <XMarkIcon
                onClick={() => removeItem(1)}
                strokeWidth={4}
                className="size-3 cursor-pointer text-neutral hover:brightness-75"
            />
        </div>
    );
};

const AndOr = () => {
    const [andOrItem, setAndOrItem] = useState("");

    return !andOrItem ? (
        <div className="flex items-center gap-1">
            <PlusIcon className="size-4 text-white" />
            <div
                onClick={() => {
                    searchProxy.connector = "AND";
                    setAndOrItem("AND");
                }}
                className="flex cursor-pointer items-start rounded-3xl bg-powder-blue px-1 py-0 text-xs font-light"
            >
                and
            </div>
            /
            <div
                onClick={() => {
                    searchProxy.connector = "OR";
                    setAndOrItem("OR");
                }}
                className="flex cursor-pointer items-start rounded-3xl bg-powder-blue px-1 py-0 text-xs font-light"
            >
                or
            </div>
        </div>
    ) : (
        <p
            onClick={() => {
                searchProxy.connector = "";
                setAndOrItem("");
            }}
            className="cursor-pointer text-xs font-light text-light-blue"
        >
            {andOrItem.toLowerCase()}
        </p>
    );
};

const SearchInput = ({
    inputRef,
    input,
    setInput,
    addNewItem,
}: {
    input: string;
    setInput: Dispatch<SetStateAction<string>>;
    inputRef: RefObject<HTMLInputElement>;
    addNewItem: (e: any) => void;
}) => {
    const { searchQuery, hideResults, setHideResults } = useNewsFeedContext();

    return (
        <input
            onKeyUp={(e: any) => addNewItem(e)}
            onChange={(e) => {
                if (hideResults) setHideResults(false);
                setInput(e.target.value);
            }}
            onFocus={() => {
                if (hideResults) setHideResults(false);
            }}
            //onPointerOver={() => setHideResults(false)}
            className="input flex size-full min-w-[4rem] border-none bg-royal-blue px-0 py-2 text-xs font-light text-light-blue focus:ring-0"
            placeholder={!searchQuery?.[0] ? "Coins, Sources, People, Companies..." : ""}
            style={{
                outline: "none",
            }}
            value={input}
            ref={inputRef}
        />
    );
};

export default SearchBar;
